import React, { useState } from "react";
// import Goldlogo from "../../images/NewGoldLogo.png";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./goldify.css";
import { GoArrowLeft } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { TbFileInvoice } from "react-icons/tb";

function Invoice() {
  const { t } = useTranslation();
  const { rateOfGold, gst } = t("goldInvoicePage");
  const {
    augmont,
    goldWeight,
    paymentDetails,
    weight,
    totalAmt,
    transactionId,
    uniqueId,
    invoiceNum,
    status,
    success,
    getInvoice,
  } = t("historyInvoicePage");

  const navigate = useNavigate();
  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  const location = useLocation();
  const data = location.state;

  console.log("DISHANT LOCATION=================", data);

  const rateofgold = data.buydata.data[0].result.data.preTaxAmount;
  const Goldweight = data.buydata.data[0].result.data.quantity;
  const GST = data.buydata.data[0].result.data.taxes.totalTaxAmount;
  // console.log("GSYTT", GST);
  const Totalamount = data.buydata.data[0].result.data.totalAmount;
  const transectionID = data.buydata.data[0].result.data.transactionId;
  const UniqueId = data.buydata.data[0].result.data.uniqueId;
  const inoviceno = data.buydata.data[0].result.data.invoiceNumber;
  const invoicePath = data?.buydata?.data?.[0]?.invoicePath;

  const [updatedInvoicePath, setUpdatedInvoicePath] = useState("");

  useEffect(() => {
    if (invoicePath) {
      if (invoicePath.includes("https://app.batuk.in/")) {
        const newPath = invoicePath.replace("https://app.batuk.in/", "");
        setUpdatedInvoicePath(newPath);
      } else {
        setUpdatedInvoicePath(invoicePath);
      }
    }
  }, [invoicePath]);

  return (
    <div style={{ width: "100%" }}>
      <div className="row d-flex justify-content-center">
        {/* <div className="col-md-1"></div> */}
        <div
          className="col-md-11  "
          style={{
            height: "auto",
            border: "2px solid #E3B041",
            borderRadius: "10px",
          }}
        >
          <div className="row d-flex justify-content-around">
            <div
              className="col  d-flex m-2 p-1"
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                border: "2px solid #E3B041",
                borderRadius: "10px",
                // boxShadow: "0px 0px 10px rgba(128, 0, 128, 0.5)",
              }}
            >
              <div className="col-md-2 ">
                <img
                  src="/gold_shield.png"
                  alt="here is logo"
                  className="img-fluid"
                  style={{ height: "80px" }}
                />
              </div>
              <div className="col-md-4   p-2 ml-5 " style={{ height: "auto" }}>
                <h2
                  className="text-center fw-bold"
                  style={{ color: "#4A2A7D" }}
                >
                  {augmont}
                </h2>
                <p className="text-center" style={{ color: "#4A2A7D" }}>
                  {goldWeight} {Goldweight} gm
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              color: "#4A2A7D",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <h4 className="m-2 fw-bold ">{paymentDetails}</h4>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{rateOfGold}</div>
              <div>{rateofgold}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{weight}</div>
              <div>{Goldweight}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{gst}</div>
              <div>{GST}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5>{totalAmt}</h5>
              <h5>{Totalamount}</h5>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div> {transactionId} </div>
              <div>{transectionID}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{uniqueId}</div>
              <div>{UniqueId}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{invoiceNum} </div>
              <div>{inoviceno}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5> {status} </h5>
              <h5 style={{ color: "green" }}>{success}</h5>
            </div>
          </div>
          <div className=" text-center mb-3">
            <a
              target="blank"
              href={updatedInvoicePath}
              className="fs-4 fw-bold"
              style={{
                textDecoration: "none",
                color: "#4A2A7D",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                gap: "5px",
              }}
            >
              <Button
                variant="contained"
                startIcon={<TbFileInvoice />}
                sx={{
                  backgroundColor: "#4a2a7d",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#4a2a7d",
                  },
                }}
              >
                Invoice
              </Button>
            </a>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
}
export default Invoice;

// import React from "react";
// import Goldlogo from "../../images/NewGoldLogo.png";
// import { Link, useLocation } from "react-router-dom";
// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import "./goldify.css";
// import { GoArrowLeft } from "react-icons/go";

// function Invoice() {
//   const navigate = useNavigate();
//   useEffect(() => {
//     const login = sessionStorage.getItem("token");
//     if (!login) {
//       navigate("/");
//     }
//   }, []);

//   const location = useLocation();
//   const data = location.state;

//   useEffect(() => {
//     if (!data || !data.response || !data.buydata) {
//     } else {
//       // console.log("Invoice data ", data);
//     }
//   }, [data]);
//   const rateofgold = data.buydata.data[0].result.data.preTaxAmount;
//   const Goldweight = data.buydata.data[0].result.data.quantity;
//   const GST = data.buydata.data[0].result.data.taxes.totalTaxAmount;
//   // console.log("GSYTT", GST);
//   const Totalamount = data.buydata.data[0].result.data.totalAmount;
//   const transectionID = data.buydata.data[0].result.data.transactionId;
//   const UniqueId = data.buydata.data[0].result.data.uniqueId;
//   const inoviceno = data.buydata.data[0].result.data.invoiceNumber;
//   const invoicepath = data.buydata.data[0].invoicePath;
//   // console.log("invoice path", invoicepath);

//   return (
//     <div style={{ width: "100%" }}>
//       {/* <Link
//         to="/dashboard/buygold"
//         className="btn btn-secondary goBack ms-5 "
//         style={{ backgroundColor: "#4A2A7D" }}
//       >
//         <span>
//           Back
//         </span>
//       </Link> */}
//       <div className="row d-flex justify-content-center">
//         {/* <div className="col-md-1"></div> */}
//         <div
//           className="col-md-11  "
//           style={{
//             height: "auto",
//             border: "2px solid #E3B041",
//             borderRadius: "10px",
//           }}
//         >
//           <div className="row d-flex justify-content-around">
//             <div
//               className="col  d-flex m-2 p-1"
//               style={{
//                 display: "flex",
//                 justifyContent: "start",
//                 alignItems: "center",
//                 border: "2px solid #E3B041",
//                 borderRadius: "10px",
//                 // boxShadow: "0px 0px 10px rgba(128, 0, 128, 0.5)",
//               }}
//             >
//               <div className="col-md-2 ">
//                 <img
//                   src={Goldlogo}
//                   alt="here is logo"
//                   className="img-fluid"
//                   style={{ height: "80px" }}
//                 />
//               </div>
//               <div className="col-md-4   p-2 ml-5 " style={{ height: "auto" }}>
//                 <h2
//                   className="text-center fw-bold"
//                   style={{ color: "#4A2A7D" }}
//                 >
//                   Augmont
//                 </h2>
//                 <p className="text-center" style={{ color: "#4A2A7D" }}>
//                   Gold Weight: {Goldweight} gm
//                 </p>
//               </div>
//             </div>
//           </div>

//           {/* <div className="row">
//             <h4 className="m-2 fw-bold " style={{ color: "#4A2A7D" }}>
//               Payment Details
//             </h4>
//             <table class="table table-borderless">
//               <tbody style={{ color: "#4A2A7D" }}>
//                 <tr>
//                   <td></td>

//                   <td>Rate of gold (&#8377;)</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td className="mr-5">{rateofgold}</td>
//                 </tr>
//                 <tr>
//                   <td></td>
//                   <td>Weight (g)</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>{Goldweight}</td>
//                 </tr>
//                 <tr>
//                   <td></td>
//                   <td>GST</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>{GST}</td>
//                 </tr>
//                 <tr>
//                   <td></td>
//                   <td className="fw-bold fs-5" style={{ color: "#4A2A7D" }}>
//                     Total Amount
//                   </td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td className="fw-bold fs-5" style={{ color: "#4A2A7D" }}>
//                     {Totalamount}
//                   </td>
//                 </tr>
//                 <tr>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                 </tr>

//                 <tr>
//                   <td></td>
//                   <td>Transaction Id/Order Id</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>{transectionID}</td>
//                 </tr>

//                 <tr>
//                   <td></td>
//                   <td>Unique Id</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>{UniqueId}</td>
//                 </tr>
//                 <tr>
//                   <td></td>
//                   <td>Invoice Number</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>{inoviceno}</td>
//                 </tr>
//                 <tr>
//                   <td></td>
//                   <td className="fs-5 fw-bold" style={{ color: "#4A2A7D" }}>
//                     Status
//                   </td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td className="text-success fs-5 fw-bold">Success</td>
//                 </tr>

//                 <tr>
//                   <td></td>
//                   <td></td>
//                   <td className=" text-center">
//                     <a
//                       target="blank"
//                       href={`${process.env.REACT_APP_BASE_URL}/` + invoicepath}
//                       className="  fs-4 fw-bold"
//                       style={{ textDecoration: "none", color: "#4A2A7D" }}
//                     >
//                       <h6 className="">
//                         <i class="fa-regular fa-copy fs-5"></i>
//                       </h6>
//                       <span>Get Invoice</span>
//                     </a>
//                   </td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                 </tr>
//               </tbody>
//             </table>
//           </div> */}

//           <div
//             style={{
//               color: "#4A2A7D",
//               display: "flex",
//               flexDirection: "column",
//               gap: "10px",
//             }}
//           >
//             <h4 className="m-2 fw-bold ">Payment Details</h4>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div>Rate of gold (₹)</div>
//               <div>{rateofgold}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div>Weight (g)</div>
//               <div>{Goldweight}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div>GST</div>
//               <div>{GST}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <h5>Total Amount</h5>
//               <h5>{Totalamount}</h5>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div> Transaction Id/Order Id </div>
//               <div>{transectionID}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div> Unique Id </div>
//               <div>{UniqueId}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div> Invoice Number </div>
//               <div>{inoviceno}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <h5> Status </h5>
//               <h5 style={{ color: "green" }}>Success</h5>
//             </div>
//           </div>
//           <div className=" text-center">
//             <a
//               target="blank"
//               href={`${process.env.REACT_APP_BASE_URL}/` + invoicepath}
//               className="  fs-4 fw-bold"
//               style={{
//                 textDecoration: "none",
//                 color: "#4A2A7D",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 textAlign: "center",
//                 gap: "5px",
//               }}
//             >
//               <h6>
//                 <i
//                   class="fa-regular fa-copy fs-5"
//                   style={{ marginTop: "9px" }}
//                 ></i>
//               </h6>
//               <span>Get Invoice</span>
//             </a>
//           </div>
//         </div>
//         <div className="col-md-2"></div>
//       </div>
//     </div>
//   );
// }
// export default Invoice;
