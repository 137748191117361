import React from "react";
import Slider from "react-slick";

export default function ShopBanner({ shopBannerApi }) {
  console.log("BANNER%%%%%%%%%%%%%%%%", shopBannerApi);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  return (
    <Slider {...settings}>
      <div>
        <img src={shopBannerApi?.[0]?.imageUrl} alt="banner1" width="100%" />
      </div>
      <div>
        <img src={shopBannerApi?.[0]?.imageUrl} alt="banner1" width="100%" />
      </div>
    </Slider>
  );
}
