import React from "react";
import { Link } from "react-router-dom";

import { CiFacebook } from "react-icons/ci";
import { CiLinkedin } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
import { CiInstagram } from "react-icons/ci";

import "./Footer.css";

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export default function Footer() {
  return (
    <div style={{ backgroundColor: "#4a2a7d" }}>
      <div
        style={{
          maxWidth: "1240px",
          margin: "auto",
          padding: "2rem 0.5rem",
          color: "white",
        }}
      >
        <div className="footer_main">
          <div className="footer_logo">
            <Link to="/">
              <img
                src="/BatukLogo.png"
                width="180"
                height="auto"
                alt="batukLogo"
              />
            </Link>
            <div className="footer_app_logo">
              <Link
                to="https://play.google.com/store/apps/details?id=com.batuk.application&pcampaignid=web_share"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/playstore.webp"
                  width="180"
                  height="auto"
                  alt="playstoreLogo"
                />
              </Link>
              <Link
                to="https://apps.apple.com/in/app/batuk/id6478106976"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/appstore.webp"
                  width="180"
                  height="auto"
                  alt="appstoreLogo"
                />
              </Link>
            </div>
          </div>
          {/* CONTACT US==========  */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "white",
            }}
          >
            <h4 style={{ color: "white" }}>Contact Us</h4>
            <span>
              <span style={{ fontWeight: "bold" }}>Head Office:</span> Office No
              2, 3rd floor, A-1, Sector 9,
              <br /> Noida, Uttar Pradesh- 201301
            </span>
            <span>
              <span style={{ fontWeight: "bold" }}>Branch Office:</span> 10/B
              Yunus Building, S.S Gaikwad Marg <br /> Near Metro Cinema, Mumbai,
              400002
            </span>
            <a
              href="tel:+918448695359"
              target="_blank"
              style={{ color: "white", textDecoration: "none" }}
            >
              <span className="animated-link">
                <strong>Phone:</strong> +91 8448695359
              </span>
            </a>
            <a
              href="mailto:connect@batuk.in"
              target="_blank"
              style={{ color: "white", textDecoration: "none" }}
            >
              <span className="animated-link">
                {" "}
                <strong>Email:</strong> connect@batuk.in
              </span>
            </a>
          </div>
          {/* EXPLORE==========  */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "white",
            }}
          >
            <h4>Explore</h4>
            <Link to="/">
              <span className="animated-link">Home</span>
            </Link>
            <Link to="/about-us" onClick={scrollToTop}>
              <span className="animated-link">About Us</span>
            </Link>
            <Link to="/contact-us" onClick={scrollToTop}>
              <span className="animated-link">Contact Us</span>
            </Link>
            <Link to="/blogs">
              <span className="animated-link">Blogs</span>
            </Link>
          </div>
          {/* RESOURSE==========  */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "white",
            }}
          >
            <h4>Resourse</h4>
            <Link to="/faqs">
              <span className="animated-link">FAQ</span>
            </Link>

            <Link to="/shipping-policy">
              <span className="animated-link">Shipping Policy</span>
            </Link>
            <Link to="/refund-policy">
              <span className="animated-link">Refund Policy</span>
            </Link>
            <Link to="/terms-conditions">
              <span className="animated-link">Terms & Conditions</span>
            </Link>
            <Link to="/privacy-policy">
              <span className="animated-link">Privacy Policy</span>
            </Link>
          </div>
        </div>
        {/* =========================================================================  */}
        <div className="social_icons_main">
          <h4>Follow us on:</h4>
          <div className="social-links">
            <Link
              to="https://www.facebook.com/batukify"
              target="_blank"
              className="social-link"
            >
              <CiFacebook size={35} color="white" />
            </Link>
            <Link
              to="https://www.linkedin.com/company/bharat-batuk-private-limited/posts/?feedView=all"
              target="_blank"
              className="social-link"
            >
              <CiLinkedin size={35} color="white" />
            </Link>
            <Link
              to="https://x.com/Batukify"
              target="_blank"
              className="social-link"
            >
              <FaXTwitter size={35} color="white" />
            </Link>
            <Link
              to="https://www.instagram.com/batukify/"
              target="_blank"
              className="social-link"
            >
              <CiInstagram size={35} color="white" />
            </Link>
          </div>
        </div>
        {/* ================================================================================= */}
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "20px",
            paddingTop: "1rem",
            borderTop: "1px solid white",
          }}
        >
          © Copyright 2024{" "}
          <Link to="/" style={{ fontWeight: "bold", color: "white" }}>
            {" "}
            Bharat Batuk Private Limited{" "}
          </Link>
          All rights reserved.
        </div>
      </div>
    </div>
  );
}
