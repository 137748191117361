// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getMessaging, getToken } from "firebase/messaging";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBJImGbOeN3vRETfxJOsCq2Zn5_I9EEv9Q",
  authDomain: "online-batuk-push-notification.firebaseapp.com",
  projectId: "online-batuk-push-notification",
  storageBucket: "online-batuk-push-notification.appspot.com",
  messagingSenderId: "760861251204",
  appId: "1:760861251204:web:3feb10bfdb540e1b5b94d1",
  measurementId: "G-EP684YHCRC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
// const analytics = getAnalytics(app);
const database = getDatabase(app);

export { app, database };

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  console.log(permission);

  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BMHcNvxF2kIqvymrcnt6jEMhxddRZYo8sP91mU9VbnsT4zN71wFg06hgpx70o3YGDy2PFYPEwDLtp9ETJxhdXCg",
    });
    console.log(token);
  }
};
