import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import Footer from "../../components/footer/Footer";
import { useNavigate } from "react-router-dom";
import "./Home.css";

const cardsData = [
  {
    name: "gold",
    img: "./gold_shield.png",
    title: "Buy Digital Gold",
    content:
      "Shop pure 24k digital gold online, BIS/NABL hallmarked, all from the comfort of your home.",
  },
  {
    name: "silver",
    img: "./silver_shield.png",
    title: "Buy Digital Silver",
    content:
      "Buy 99.9% pure digital silver online, BIS/NABL hallmarked, with complete convenience at home.",
  },
];

export default function Home() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        margin: "2rem 0",
      }}
    >
      <div className="home_banner1 universal_container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "2rem",
          }}
        >
          <h1
            style={{
              fontWeight: "700",
              fontSize: "2.5rem",
            }}
          >
            Sapno Ke Rang <br />
            <span style={{ color: "#EDBF00" }}> Batuk</span> Ke Sang
          </h1>
          <div style={{ fontWeight: "500" }}>
            Batuk is a trusted platform that offers convenient, secure, and
            accessible ways to purchase and save in Gold and Silver, starting
            from just ₹100.
          </div>
          {/* ****START SAVING**** */}
          <div>
            <button
              onClick={() => navigate("/dashboard/buygold")}
              style={{
                borderRadius: "100px",
                padding: "10px 35px",
                border: "none",
                fontSize: "20px",
                color: "white",
                fontWeight: "bold",
                backgroundColor: "#4a2a7d",
                transition:
                  "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "translateY(-10px)";
                e.currentTarget.style.boxShadow =
                  "0 10px 20px rgba(0, 0, 0, 0.3)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "translateY(0)";
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              Start Saving
            </button>
          </div>
          {/* ****POWERED BY**** */}
          <div>
            <h4>Powered by</h4>
            <div className="powered_main">
              <img src="./augmont_logo.png" alt="augmontLogo" width="25%" />
              <img src="./MMTC-logo.png" alt="augmontLogo" width="25%" />
            </div>
          </div>
        </div>
        {/* HOME BANNER  */}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <img src="./home_main.png" alt="gold" width="85%" />
        </div>
      </div>
      {/* ====================================Save EffortLessly====================================== */}
      <div style={{ width: "100%", backgroundColor: "#dcdaff" }}>
        <div
          className="home_banner2"
          style={{
            maxWidth: "1240px",
            margin: "2rem auto",
            padding: "0.5rem",
          }}
        >
          <img
            src="./home_banner_2.png"
            alt="homeeeee"
            className="banner2_mobile_img"
          />
          <div>
            <h1>Save Effortlessly!</h1>
            <p
              style={{
                fontWeight: "500",
              }}
            >
              Start saving with ease using Batuk’s digital gold. With just a few
              taps, you can begin building your wealth without any hassle. Enjoy
              the convenience of managing your savings from your phone and watch
              your gold grow effortlessly. Secure your future today with Batuk!
            </p>
          </div>
        </div>
      </div>
      {/* ============================================================================================ */}
      <div
        style={{
          maxWidth: "1240px",
          margin: "3rem auto",
          padding: "0.5rem",
        }}
      >
        <h2
          style={{ marginBottom: "1rem", color: "#4a2a7d" }}
          className="hassle_free_heading"
        >
          Explore our hassle-free solutions
        </h2>
        <div className="home_banner3">
          {cardsData.map((item, i) => (
            <div key={i} className="home_banner3_card">
              <div>
                <img src={item.img} alt={item.name} width={170} />
              </div>
              <div>
                <h3 style={{ color: "#4a2a7d" }}>{item.title}</h3>
                <p style={{ color: "#4a2a7d" }}>{item.content}</p>
                <button
                  onClick={() => navigate(`/digi-${item.name}`)}
                  style={{
                    width: "100%",
                    border: "2px solid #442673",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    padding: "12px",
                    color: "#4a2a7d",
                    fontSize: "20px",
                    transition:
                      "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "translateY(-10px)";
                    e.currentTarget.style.boxShadow =
                      "0 10px 20px rgba(0, 0, 0, 0.3)";
                    e.currentTarget.style.backgroundColor = "#442673"; // Change background color on hover
                    e.currentTarget.style.color = "white"; // Change font color on hover
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "translateY(0)";
                    e.currentTarget.style.boxShadow = "none";
                    e.currentTarget.style.backgroundColor = "white"; // Revert background color
                    e.currentTarget.style.color = "#442673"; // Revert font color
                  }}
                >
                  View Details <FaArrowRightLong />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* ====================================EMPANEL===================================== */}
      <div
        style={{
          width: "100%",
          backgroundColor: "#DCDAFF",
          // margin: "4rem 0",
        }}
      >
        <div className="universal_container empanel_banner">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <h2
              style={{
                color: "#4a2a7d",
              }}
            >
              Empanel with Batuk!
            </h2>
            <p
              style={{
                fontSize: "1.5rem",
                fontweight: "bold",
                color: "#4a2a7d",
              }}
            >
              Join us and Reimagine the magic of Gold with us
            </p>
            <div>
              <button
                onClick={() => navigate("/contact-Us")}
                style={{
                  border: "none",
                  borderRadius: "10px",
                  backgroundColor: "#442673",
                  padding: "12px",
                  color: "white",
                  fontSize: "20px",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-10px)";
                  e.currentTarget.style.boxShadow =
                    "0 10px 20px rgba(0, 0, 0, 0.3)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow = "none";
                }}
              >
                Connect Now <FaArrowRightLong />
              </button>
            </div>
          </div>
          <img
            src="./gold _revolution.png"
            alt="gold"
            className="empanel_img"
          />
        </div>
      </div>
      {/* =======================================SAVE & SECURE================================ */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "1240px",
          margin: "3rem auto",
          padding: "0.5rem",
          gap: "1rem",
        }}
        className="secure_main"
      >
        <img src="./homeBanner2.webp" alt="augmontImg" className="secure_img" />
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <h2 style={{ color: "#4a2a7d" }}>
            Secure Your Future with 24K Gold!
          </h2>
          <p style={{ color: "#4a2a7d", fontWeight: "500" }}>
            Enjoy the convenience of managing pure, high-quality gold digitally
            with flexible savings plans and security. Start building your wealth
            effortlessly with Batuk today!
          </p>
        </div>
      </div>
    </div>
  );
}
