import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";

import "../address/Address.css";
import { useFormik } from "formik";
import {
  TextField,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Rating,
} from "@mui/material";
import { useEffect } from "react";
import {
  BillingAddressSchema,
  ShippingAddressSchema,
} from "../../utils/validationSchemas";
import { getStateApi } from "../../redux/features/address/stateSlice";
import { getCityApi } from "../../redux/features/address/citySlice";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  razorpayOrderApi,
  resetState,
} from "../../redux/features/razorpay/razorpayOrderSlice";
import { razorpaySpotGoldOrderApi } from "../../redux/features/razorpay/razorpaySpotGoldOrderSlice";
import { getProfileApi } from "../../redux/features/profileSlice";
import { userAddressApi } from "../../redux/features/user/userAddressSlice";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: "#4a2a7d",
          "&.Mui-active": {
            color: "#4a2a7d",
          },
          "&.Mui-completed": {
            color: "#4a2a7d",
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: "#4a2a7d", // Circle color for non-active/completed steps
          "&.Mui-active": {
            color: "#4a2a7d", // Active step circle color
          },
          "&.Mui-completed": {
            color: "#4a2a7d", // Completed step circle color
          },
        },
      },
    },
  },
});

const steps = ["Billing Address", "Shipping Address", "Payment"];

export default function Checkout() {
  const location = useLocation();
  const navigate = useNavigate();

  // console.log("DISHANT LOCATION===========", location.state);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // ============================PROFILE===================================

  const profileSliceResponse = useSelector(
    (state) => state?.profileSlice?.data
  );

  console.log("PROFILE API=====================", profileSliceResponse);

  useEffect(() => {
    dispatch(getProfileApi());
  }, []);

  // =========================USER ADDRESS=================================

  const userAddressSlice = useSelector(
    (state) => state.userAddressSlice.data.data
  );

  useEffect(() => {
    dispatch(userAddressApi());
  }, []);

  // -------------------------BILLING ADDRESS---------------------

  const dispatch = useDispatch();
  const stateSliceResponse = useSelector((state) => state.stateSlice.data);
  const citySliceResponse = useSelector((state) => state.citySlice.data);

  console.log("STATE API#####################", stateSliceResponse.data);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      pan: "",
      dob: "",
      address: "",
      state: "",
      city: "",
      landmark: "",
      zip: "",
    },
    validationSchema: BillingAddressSchema,
    onSubmit: (values) => {
      // console.log("DISHANT VALUES+++++++++++++++++++++", values);
      handleNext();
    },
    validateOnMount: false, // No validation on mount
    validateOnChange: false, // No validation on change
    validateOnBlur: true, // Validate only on blur
  });

  // ==============================FUNCTIONS===================================

  const handleStateChange = (event, value) => {
    if (value) {
      dispatch(getCityApi(value.id));
      setFieldValue("state", value ? value.name : "");
      // setCityApiData();
    } else {
      console.log("Selection cleared");
    }
  };

  const handleCityChange = (event, value) => {
    setFieldValue("city", value ? value.name : "");
  };

  useEffect(() => {
    dispatch(getStateApi());
  }, []);

  useEffect(() => {
    if (userAddressSlice?.length > 0 && userAddressSlice[0]?.stateName) {
      dispatch(getStateApi(userAddressSlice[0].stateName));
    }
  }, [userAddressSlice, values]);

  const { landMark, postalCode, stateName, cityName, customerAddress } =
    userAddressSlice?.[0] || [];

  const state = stateSliceResponse?.data?.find(
    (item) => item.name === stateName
  );
  const stateId = state ? state.id : null;

  console.log("STATE ID***************", stateId);

  useEffect(() => {
    dispatch(getCityApi(stateId));
  }, [stateId]);

  // ====================NAME SEPRATE======================================
  useEffect(() => {
    if (
      profileSliceResponse &&
      profileSliceResponse.data &&
      profileSliceResponse.data[0]
    ) {
      const profileData = profileSliceResponse.data[0];
      let firstName = "";
      let lastName = "";

      // console.log("FULL NAME===========", profileData?.fullName);

      if (profileData?.fullName) {
        const nameParts = profileData?.fullName?.trim().split(" ");
        firstName = nameParts[0] || ""; // First part is considered as the first name

        // If there's only one part, use it as both first and last name
        if (nameParts.length > 1) {
          lastName = nameParts.slice(1).join(" "); // Remaining parts are considered as the last name
        } else {
          lastName = firstName; // If no last name, use the first name as the last name
        }
      }

      const convertToDateInputFormat = (dob) => {
        const [day, month, year] = dob.split("-");
        return `${year}-${month}-${day}`;
      };

      const userDetails = JSON.parse(localStorage.getItem("userDetails"));

      setValues({
        dob: profileData?.dob || "",
        phone: profileData?.mobileNumber || "",
        firstName: firstName || "",
        lastName: lastName || "",
        email: profileData?.emailAddress || userDetails?.email || "",
        pan: profileData?.kyc?.pan_no || "",
        landmark: landMark || "",
        zip: postalCode || "",
        state: stateName || "",
        city: cityName || "",
        address: customerAddress || "",
        // landmark: userAddressSlice[0]?.landMark || "",
        // zip: userAddressSlice[0]?.postalCode || "",
        // state: userAddressSlice[0]?.stateName || "",
        // city: userAddressSlice[0]?.cityName || "",
        // address: userAddressSlice[0]?.customerAddress || "",
      });
    } else {
      resetForm();
    }
  }, [profileSliceResponse, setValues, resetForm]);

  // ===================================SHIPPING ADDRESS============================================

  const [checkBoxState, setCheckBoxState] = React.useState(false);

  const formikShipping = useFormik({
    initialValues: {
      address: "",
      state: "",
      city: "",
      landmark: "",
      zip: "",
    },
    validationSchema: ShippingAddressSchema,
    onSubmit: (values) => {
      console.log(
        "Shipping Address formik VALUES+++++++++++:",
        formikShipping.values
      );
      console.log("Shipping Address VALUES---------------:", values);
      handleNext();
    },
  });

  const handleShippingState = (event, value) => {
    if (value) {
      dispatch(getCityApi(value.id));
      formikShipping.setFieldValue("state", value ? value.name : "");
    } else {
      console.log("Selection cleared");
    }
  };

  const handleShippingCity = (event, value) => {
    formikShipping.setFieldValue("city", value ? value.name : "");
  };

  const handleCheckboxChange = (event) => {
    setCheckBoxState(!checkBoxState);
    // dispatch(getCityApi(value.id));
  };

  useEffect(() => {
    console.log("DISHANT VALUES==================", values);
    if (checkBoxState) {
      formikShipping.setValues({
        address: values.address,
        state: values.state,
        city: values.city,
        landmark: values.landmark,
        zip: values.zip,
      });
    } else {
      formikShipping.resetForm();
    }
  }, [checkBoxState, values]);

  // ========================================PAYMENT===============================

  const razorpayOrderSliceResponse = useSelector(
    (state) => state.razorpayOrderSlice.data.data
  );

  const paymentHandler = (TotalAmount) => {
    if (!TotalAmount) {
      toast.error("Please try again later...");
      return;
    }

    const payload = {
      TotalAmount: location.state.totalAmount,
    };
    dispatch(razorpayOrderApi(payload));
  };

  const transformedProducts = location?.state?.products?.map((product) => ({
    productId: product.product_id,
    quantity: product.quantity,
    paymentTypeId: 0, // Assuming paymentTypeId is always 0 as per your request
  }));

  const handleOpenRazorpay = async () => {
    const options = {
      key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
      amount: razorpayOrderSliceResponse?.amount,
      currency: razorpayOrderSliceResponse?.currency,
      name: "Bharat Batuk Pvt.ltd.",
      description: "XYZ",
      order_id: razorpayOrderSliceResponse?.id,
      handler: function (response) {
        console.log("DISHANT RESPONSE+++++++++++++++++++", response);

        const payload = {
          productDetails: transformedProducts,
          metalType: "gold",
          mobileNumber: values.phone,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          customerAddress: values.address,
          landMark: values.landmark,
          postalCode: values.zip,
          stateName: values.state,
          cityName: values.city,
          shippingAddress: values.address,
          shippingLandMark: values.landmark,
          shippingPostalCode: values.zip,
          shippingStateName: values.state,
          shippingCityName: values.city,
          panCardNumber: values.pan,
          dateOfBirth: values.dob,
        };

        const orderResponse = dispatch(razorpaySpotGoldOrderApi(payload));
        dispatch(resetState());

        console.log("DISHANT=================", orderResponse);
        Swal.fire({
          title: "Payment Successful",
          icon: "success",
        });
        navigate("/dashboard/history");
      },
      prefill: {
        name: values.firstName + " " + values.lastName,
        email: values.email,
        contact: values.phone,
      },
    };

    const rzp = new window.Razorpay(options);

    // Handling payment failure
    rzp.on("payment.failed", function (response) {
      console.log("Payment failed:", response);
      Swal.fire({
        title: "Payment Failed",
        text: response.error.description,
        icon: "error",
      });
    });

    rzp.open();
  };

  useEffect(() => {
    if (razorpayOrderSliceResponse?.id) {
      handleOpenRazorpay();
    }
  }, [razorpayOrderSliceResponse]);

  return (
    <div>
      <div className="main-box" style={{ padding: "20px" }}>
        {/* ======================PRODUCT DETAILS================================== */}
        <div>
          <div
            style={{
              // margin: "20px",
              backgroundColor: "white",
              padding: "20px",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              borderRadius: "10px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5>Order Summary</h5>
              <h5>Total: ₹{location?.state?.totalAmount}</h5>
            </div>
            <hr />
            {location?.state?.products?.map((item, i) => (
              <div key={i}>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    // border: "1px solid lightgray",
                    padding: "10px",
                    borderRadius: "10px",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ width: "20%" }}>
                    <img
                      src={item.productDetails.productImage}
                      alt="item"
                      width="100%"
                    />
                  </div>
                  <div>
                    <h5>{item.productDetails.productName}</h5>
                    <div>Weight: {item.productDetails.weight}gm</div>
                    <div>Quantity: {item.quantity}</div>
                    {/* <Rating name="read-only" defaultValue={4} readOnly /> */}
                    <h5>
                      ₹{item.productDetails.productPrice[0]?.finalProductPrice}
                    </h5>
                    {/* <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <FormControl sx={{ width: "100px" }} size="small">
                      <InputLabel id={`quantity-label-${i}`}>
                        Quantity
                      </InputLabel>
                      <Select
                        labelId={`quantity-label-${i}`}
                        id={`quantity-select-${i}`}
                        value={item.quantity}
                        label="Quantity"
                        // onChange={(e) => handleChange(e, item.product_id)}
                      >
                        {[...Array(6).keys()].map((value) => (
                          <MenuItem key={value + 1} value={value + 1}>
                            {value + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Button onClick={() => handleRemove(item.product_id)}>
                      Remove
                    </Button>
                  </div> */}
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>

        {/* ==============================ADDRESS============================== */}

        <Box sx={{ width: "100%" }}>
          <ThemeProvider theme={theme}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </ThemeProvider>
          <React.Fragment>
            {activeStep === 0 && (
              <div>
                <form className="main-container" onSubmit={handleSubmit}>
                  <div className="inputfield-container">
                    <TextField
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.firstName}
                      onChange={handleChange}
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                    />
                    <TextField
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.lastName}
                      onChange={handleChange}
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                    />
                  </div>
                  <div className="inputfield-container">
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.email}
                      onChange={handleChange}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                    <TextField
                      id="phone"
                      name="phone"
                      label="Phone Number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.phone}
                      onChange={handleChange}
                      error={!!errors.phone}
                      helperText={errors.phone}
                    />
                  </div>
                  <div className="inputfield-container">
                    <TextField
                      id="pan"
                      name="pan"
                      label="Pan Number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.pan}
                      onChange={handleChange}
                      error={!!errors.pan}
                      helperText={errors.pan}
                    />
                    <TextField
                      id="dob"
                      name="dob"
                      label="Date Of Birth"
                      variant="outlined"
                      placeholder="date"
                      size="small"
                      type="date"
                      fullWidth
                      value={values.dob}
                      onChange={handleChange}
                      error={!!errors.dob}
                      helperText={errors.dob}
                    />
                  </div>
                  <div className="inputfield-container">
                    <Autocomplete
                      disablePortal
                      id="state-autocomplete"
                      options={stateSliceResponse?.data || []}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      size="small"
                      value={
                        (stateSliceResponse?.data || []).find(
                          (option) => option.name === values.state
                        ) || null
                      }
                      onChange={handleStateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          error={!!errors.state}
                          helperText={errors.state}
                        />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      id="city-autocomplete"
                      options={citySliceResponse?.data || []}
                      getOptionLabel={(option) => option.name || ""}
                      fullWidth
                      size="small"
                      value={
                        (citySliceResponse?.data || []).find(
                          (option) => option.name === values.city
                        ) || { name: values.city }
                      }
                      onChange={handleCityChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="City"
                          error={!!errors.city}
                          helperText={errors.city}
                        />
                      )}
                    />
                  </div>
                  <div className="inputfield-container">
                    <TextField
                      id="landmark"
                      name="landmark"
                      label="Landmark"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.landmark}
                      onChange={handleChange}
                      error={!!errors.landmark}
                      helperText={errors.landmark}
                    />
                    <TextField
                      id="zip"
                      name="zip"
                      label="Zip Code"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.zip}
                      onChange={handleChange}
                      error={!!errors.zip}
                      helperText={errors.zip}
                    />
                  </div>
                  <div className="inputfield-container">
                    <TextField
                      id="address"
                      name="address"
                      label="Address"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.address}
                      onChange={handleChange}
                      error={!!errors.address}
                      helperText={errors.address}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "#472878",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#472878",
                        },
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </form>
              </div>
            )}
            {/* ==============shipping Address========================= */}
            {activeStep === 1 && (
              <div>
                <form
                  className="main-container"
                  onSubmit={formikShipping.handleSubmit}
                >
                  <FormControlLabel
                    control={<Checkbox checked={checkBoxState} />}
                    label="Same As Billing Address"
                    onClick={handleCheckboxChange}
                  />
                  <div className="inputfield-container">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={stateSliceResponse?.data || []}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      size="small"
                      value={
                        (stateSliceResponse?.data || []).find(
                          (option) =>
                            option.name ===
                            (checkBoxState
                              ? values.state
                              : formikShipping.values.state)
                        ) || null
                      }
                      onChange={handleShippingState}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          onChange={handleShippingState}
                          error={formikShipping.errors.state ? true : false}
                          helperText={formikShipping.errors.state}
                        />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={citySliceResponse?.data || []}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      size="small"
                      value={
                        (citySliceResponse?.data || []).find(
                          (option) =>
                            option.name ===
                            (checkBoxState
                              ? values.city
                              : formikShipping.values.city)
                        ) || null
                      }
                      onChange={handleShippingCity}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="City"
                          onChange={handleShippingCity}
                          error={formikShipping.errors.city ? true : false}
                          helperText={formikShipping.errors.city}
                        />
                      )}
                    />
                  </div>
                  <div className="inputfield-container">
                    <TextField
                      id="landmark"
                      name="landmark"
                      label="Landmark"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={
                        checkBoxState
                          ? values.landmark
                          : formikShipping.values.landmark
                      }
                      onChange={formikShipping.handleChange}
                      error={formikShipping.errors.landmark ? true : false}
                      helperText={formikShipping.errors.landmark}
                    />
                    <TextField
                      id="zip"
                      type="tel"
                      name="zip"
                      label="Zip Code"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={
                        checkBoxState ? values.zip : formikShipping.values.zip
                      }
                      onChange={formikShipping.handleChange}
                      error={formikShipping.errors.zip ? true : false}
                      helperText={formikShipping.errors.zip}
                      inputProps={{ maxLength: 6 }}
                    />
                  </div>
                  <div className="inputfield-container">
                    <TextField
                      id="address"
                      name="address"
                      label="Address"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={
                        checkBoxState
                          ? values.address
                          : formikShipping.values.address
                      }
                      onChange={formikShipping.handleChange}
                      error={formikShipping.errors.address ? true : false}
                      helperText={formikShipping.errors.address}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={handleBack}
                      sx={{
                        backgroundColor: "#472878",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#472878",
                        },
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        backgroundColor: "#472878",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#472878",
                        },
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </form>
              </div>
            )}

            {activeStep === 2 && (
              <div
                style={{
                  margin: "20px",
                  padding: "20px",
                  border: "1px solid lightgray",
                  borderRadius: "10px",
                }}
              >
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                  <h4 style={{ marginBottom: "10px" }}>Billing Address</h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>Name:</div>
                    <div>
                      {values.firstName} {values.lastName}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>Address:</div>
                    <div>{values.address}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>State:</div>
                    <div>{values.state}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>City:</div>
                    <div>{values.city}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>Landmark:</div>
                    <div>{values.landmark}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>Zip Code:</div>
                    <div>{values.zip}</div>
                  </div>
                </div>

                <div
                  style={{
                    borderBottom: "1px dashed gray",
                    margin: "20px 0px",
                  }}
                ></div>
                <div style={{ textAlign: "center", marginBottom: "25px" }}>
                  <h4 style={{ marginBottom: "10px" }}>Shipping Address</h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>Address:</div>
                    <div>{formikShipping.values.address}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>State:</div>
                    <div>{formikShipping.values.state}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>City:</div>
                    <div>{formikShipping.values.city}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>Landmark:</div>
                    <div>{formikShipping.values.landmark}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>Zip Code:</div>
                    <div>{formikShipping.values.zip}</div>
                  </div>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    type="button"
                    variant="contained"
                    onClick={handleBack}
                    sx={{
                      backgroundColor: "#472878",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#472878",
                      },
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={paymentHandler}
                    sx={{
                      backgroundColor: "#472878",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#472878",
                      },
                    }}
                  >
                    Proceed To Pay
                  </Button>
                </div>
              </div>
            )}

            {/* {activeStep === 2 && (
                <div
                  style={{
                    margin: "20px",
                    padding: "20px",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                  }}
                >
                  <div style={{ textAlign: "center", marginBottom: "20px" }}>
                    <h4 style={{ marginBottom: "10px" }}>Billing Address</h4>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>Name:</div>
                      <div>
                        {values.firstName} {values.lastName}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>Address:</div>
                      <div>{values.address}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>State:</div>
                      <div>{values.state}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>City:</div>
                      <div>{values.city}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>Landmark:</div>
                      <div>{values.landmark}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>Zip Code:</div>
                      <div>{values.zip}</div>
                    </div>
                  </div>

                  <div
                    style={{
                      borderBottom: "1px dashed gray",
                      margin: "20px 0px",
                    }}
                  ></div>
                  <div style={{ textAlign: "center", marginBottom: "25px" }}>
                    <h4 style={{ marginBottom: "10px" }}>Shipping Address</h4>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>Address:</div>
                      <div>{formikShipping.values.address}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>State:</div>
                      <div>{formikShipping.values.state}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>City:</div>
                      <div>{formikShipping.values.city}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>Landmark:</div>
                      <div>{formikShipping.values.landmark}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>Zip Code:</div>
                      <div>{formikShipping.values.zip}</div>
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleBack}
                      sx={{
                        backgroundColor: "#472878",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#472878",
                        },
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={paymentHandler}
                      sx={{
                        backgroundColor: "#472878",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#472878",
                        },
                      }}
                    >
                      Proceed To Pay
                    </Button>
                  </div>
                </div>
              )} */}
          </React.Fragment>
        </Box>
      </div>
    </div>
  );
}
