import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./tiles.scss";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Navbar from "./components/Navbar";
import { useTranslation } from "react-i18next";

// Initialize AOS library
AOS.init();

// import Navbar from '../TopNavbar/navbar';

function Tiless() {
  const { t } = useTranslation();
  const {
    goldTitle,
    silverTitle,
    portfolioTitle,
    historyTitle,
    profileTitle,
    gold,
    silver,
    portfolio,
    history,
    profile,
  } = t("home");

  const homeLinks = [
    {
      name: "home",
      img: "/goldHome.png",
      title: goldTitle,
      link: "/dashboard/buygold",
      contentKey: gold,
    },
    {
      name: "home",
      img: "/silverHome.png",
      title: silverTitle,
      link: "/dashboard/buysilver",
      contentKey: silver,
    },
    {
      name: "spotGold",
      img: "/jewellery_main.png",
      title: "Jewellery",
      // link: "/dashboard/spotGold",
      link: "/shop",
      contentKey: gold,
    },
    // {
    //   name: "spotGold",
    //   img: "./goldFDLogo.png",
    //   title: "Gold FD",
    //   link: "/dashboard/goldFD",
    //   contentKey: gold,
    // },
    {
      name: "home",
      img: "/portfolioHome.png",
      title: portfolioTitle,
      link: "/dashboard/portfolio",
      contentKey: portfolio,
    },
    {
      name: "home",
      img: "/profileHome.png",
      title: profileTitle,
      link: "/dashboard/profile",
      contentKey: profile,
    },
    {
      name: "home",
      img: "/historyHome.png",
      img2: "/history2.png",
      title: historyTitle,
      link: "/dashboard/history",
      contentKey: history,
    },
  ];

  const navigate = useNavigate();
  const Token = sessionStorage.getItem("token");
  const [open, setOpen] = React.useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [anchorEl6, setAnchorEl6] = React.useState(null);
  const open6 = Boolean(anchorEl6);
  const handleClick6 = (event) => {
    setAnchorEl6(event.currentTarget);
  };
  const handleClose6 = () => {
    setAnchorEl6(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open5 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  return (
    <div style={{ backgroundColor: "#DCDAFF", minHeight: "100vh" }}>
      {/* <TopNavigation /> */}

      {/* <Navbar /> */}

      <div
        className="Tiles"
        style={{
          width: "90%",
          margin: "auto",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          padding: "40px 0",
        }}
      >
        <div>
          <div
            className=" mb-4"
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              width: "100%",
            }}
          >
            {homeLinks.map((item, i) => (
              <div
                key={i}
                className="home-card"
                onMouseEnter={() => setHoveredIndex(i)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <Link to={item.link} style={{ width: "100%" }}>
                  <div className="tiles_card" style={{ width: "100%" }}>
                    <div
                      // className="card-body d-flex flex-row"
                      style={{ width: "100%", display:"flex",gap:"10px",justifyContent:"space-between" }}
                    >
                      <img
                        src={
                          hoveredIndex === i && item.img2 ? item.img2 : item.img
                        }
                        alt={t(item.title)}
                        width="20%"
                      />
                      <div
                        className="content"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h4
                          className="title"
                          style={{ color: "#4A2A7D", fontSize: "1.5rem" }}
                        >
                          {t(item.title)}
                        </h4>
                        <p
                          className="desc"
                          style={{ color: "#4A2A7D", fontSize: "0.9rem" }}
                        >
                          {/* {item.content} */}
                          {/* {t(item.contentKey)} */}
                          {item.contentKey}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}

            {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4 mb-4">
              <Link to="/dashboard/buygold">
                <div className="card tiles_card">
                  <div className="card-body d-flex flex-row">
                    <img className="tiles_image" src={Gold} alt="Goldify" />
                    <div className="content m-3">
                      <h4 className="title" style={{ color: "#4A2A7D" }}>
                        Digital Gold
                      </h4>
                      <h6 className="desc" style={{ color: "#4A2A7D" }}>
                        We offer a wide variety of gold products, including
                        bars, coins, and jewelry. We also offer a variety of
                        services, such as gold appraisals, gold buybacks, and
                        gold storage.
                      </h6>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4 mb-4">
              <Link to="/dashboard/buysilver">
                <div className="card tiles_card">
                  <div className="card-body d-flex flex-row">
                    <img className="tiles_image" src={Silver} alt="Silivify" />
                    <div className="content m-3">
                      <h4 className="title" style={{ color: "#4A2A7D" }}>
                        Digital Silver
                      </h4>
                      <h6 className="desc" style={{ color: "#4A2A7D" }}>
                        We offer a seamless platform for buying and selling
                        silver, providing you with a secure and efficient way to
                        invest in this precious metal silver, providing you with
                        a secure.
                      </h6>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4 mb-4">
              <Link to="/dashboard/portfolio">
                <div className="card tiles_card">
                  <div className="card-body d-flex flex-row">
                    <img
                      className="tiles_image"
                      src={portflioimg}
                      alt="Portfolio"
                    />
                    <div className="content m-3">
                      <h4 className="title" style={{ color: "#4A2A7D" }}>
                        Portfolio
                      </h4>
                      <h6 className="desc" style={{ color: "#4A2A7D" }}>
                        Welcome to your personal portfolio! This is where your
                        projects come to life and showcase your skills and
                        expertise.
                      </h6>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4 mb-4">
              <Link to="/dashboard/history">
                <div className="card tiles_card">
                  <div className="card-body d-flex flex-row">
                    <img className="tiles_image" src={History} alt="History" />
                    <div className="content m-3">
                      <h4 className="title" style={{ color: "#4A2A7D" }}>
                        History
                      </h4>
                      <h6 className="desc" style={{ color: "#4A2A7D" }}>
                        Take a moment to explore your history, track your
                        progress and see how your interests and preferences
                        evolve over time.
                      </h6>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-6 col-xxl-4 mb-4">
              <Link to="/dashboard/profile">
                <div className="card tiles_card">
                  <div className="card-body d-flex flex-row">
                    <img
                      className="tiles_image"
                      src={profileimg}
                      alt="Profile"
                    />
                    <div className="content m-3">
                      <h4 className="title" style={{ color: "#4A2A7D" }}>
                        Profile
                      </h4>
                      <h6 className="desc" style={{ color: "#4A2A7D" }}>
                        This is your digital identity where you can manage your
                        account details, preferences, and personal information.
                      </h6>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
          </div>
        </div>
      </div>

      {/* <Footer /> */}
      {/* <div className='container-fluid mt-2' style={{ backgroundColor: "rgb(243 241 245)", boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)", position: "absolute", bottom: 0 }}>
                <div className='row  text-center p-3 text-dark '>
                    <h5>All rights are reserved by @Bharat Batuk Pvt. Ltd.</h5>
                </div>
            </div> */}
    </div>
  );
}

export default Tiless;
