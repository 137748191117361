import React, { useEffect, useState } from "react";
// import gold from "../../images/Goldd.png";
// import { FaRupeeSign } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSpotGoldProductDetailsApi } from "../../redux/features/spotGold/spotGoldProductDetailsSlice";
import { toast } from "react-toastify";

const Confirmation = ({ data, useraddress }) => {
  //   console.log("DISHANT DATA+++++++++++++++", data);
  //   console.log("DISHANT ADDRESS+++++++++++++++", useraddress);
  const navigate = useNavigate();
  const location = useLocation();
  console.log("LOCATION^^^^^^^^^^++++", location.state);  

  const [coinDetail, setCoinDetail] = useState(location.state.coinDetail);
  const [address, setAddress] = useState(location.state.address);

  const dispatch = useDispatch();

  const sliceResponse = useSelector(
    (state) => state.spotGoldProductDetailsSlice.spotGoldProductDetails.data
  );
  // console.log("SLICE RESPOSNE++++++++++++++++++++++++++++", sliceResponse);

  useEffect(() => {
    dispatch(getSpotGoldProductDetailsApi(location.state.coinDetail));
  }, [location.state.coinDetail]);

  const { t } = useTranslation();
  const { checkout, purchaseDetails, price, deliveryAt, buyBtn } =
    t("confirmationPage");

  useEffect(() => {
    const login = sessionStorage.getItem("isLOGIN");
    if (!login) {
      navigate("/");
    }
  }, []);

  const Token = sessionStorage.getItem("token");
  const [razorpayResponse, setRazorpayResponse] = useState(null);

  const handleOpenRazorpay = async (responseData) => {
    const options = {
      key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
      amount: Number(responseData.amount),
      currency: responseData.currency,
      name: "Bharat Batuk Pvt.ltd.",
      description: "XYZ",
      order_id: responseData.id,
      handler: function (response) {
        // console.log(response);
        oderplaceApi();
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const oderplaceApi = () => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Token}`,
    };

    const payload = {
      product1: coinDetail.sku,
      quantity1: "1",
      addressId: address._id,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/redeem/postOrder`, payload, {
        headers: headers,
      })
      .then((res) => {
        const responseData = res.data?.data[0];
        // console.log("responseData@@@@@@@@@@@@@@@@", responseData);
        // console.log(
        //   "DISHANT RESPONSE+++++++++++++++++++++",
        //   res?.data?.data[0]?.result?.data.merchantTransactionId
        // );
        Swal.fire({
          title: "Payment Successful",
          text: `Transaction Number: ${res?.data?.data[0]?.result?.data.merchantTransactionId}`,
          icon: "success",
        });
        navigate("/dashboard/history");
      })
      .catch((err) => {
        console.log("DISHANT ERROR+++++++++++++++++++++", err);
      });
  };

  const handlePayment = (TotalAmount) => {
    if (!TotalAmount) {
      toast.error("Please try again later...");
      return;
    }
    const requestData = { TotalAmount: TotalAmount };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/orders`, requestData)
      .then((res) => {
        const responseData = res.data.data;
        handleOpenRazorpay(responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
    // style={{ boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)" }}
    >
      <div className="p-4">
        <div className="row">
          <div className="col-12">
            <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
              {checkout}
            </h3>
            <div className="contentshadow">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 20,
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={
                      coinDetail.metalType === "gold"
                        ? "/goldcoin.png"
                        : coinDetail.metalType === "silver"
                        ? "/silvercoin.png"
                        : sliceResponse?.productImage
                        ? sliceResponse.productImage
                        : "/defaultcoin.png"
                    }
                    alt="coin"
                    style={{ width: "100px" }}
                  />
                </div>
                <div>
                  <h5>
                    {coinDetail.name ||
                      sliceResponse?.productName ||
                      "Default Name"}
                  </h5>
                  <p>
                    {coinDetail.productWeight ||
                      sliceResponse?.weight ||
                      "Default Weight"}{" "}
                    gm
                  </p>
                  <p>
                    ₹
                    {coinDetail.basePrice ||
                      sliceResponse?.productPrice[0]?.finalProductPrice ||
                      "Default Price"}
                  </p>
                  {/* <h5>{coinDetail.name}</h5> */}
                  {/* <p>{coinDetail.productWeight} gm</p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="contentshadow">
              <h5 className="colored">{purchaseDetails}</h5>
              {/* <div>{coinDetail.description}</div> */}
              <p>
                {coinDetail.description ||
                  sliceResponse?.metaDescription ||
                  "Default Description"}
              </p>
            </div>
          </div>
          {/* <div className="col-12">
            <div className="contentshadow">
              <h5 className="colored">{price}</h5>
              <div> ₹{coinDetail.basePrice}</div>
              <div>
                ₹
                {sliceResponse?.productPrice[0]?.finalProductPrice
                  ? sliceResponse?.productPrice[0]?.finalProductPrice
                  : coinDetail.basePrice}
              </div>
            </div>
          </div> */}
          <div className="col-12">
            <div className="contentshadow">
              <h5 className="colored">{deliveryAt}</h5>
              <div>
                {address.line1} {address.line2}
              </div>
              <div>
                {address.city} {address.state}
              </div>
              <div>{address.mobileNumber}</div>
              <div>{address.zip}</div>
            </div>
          </div>
          <div
            className="col-12"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 20,
            }}
          >
            <button
              className="btn w-75 text-light"
              onClick={() => handlePayment(coinDetail.basePrice)}
              style={{ backgroundColor: "#472878" }}
            >
              {buyBtn}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
