import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getProductCartApi } from "../../redux/features/cart/getProductCartSlice";
import { removeProductCartApi } from "../../redux/features/cart/removeProductCartSlice";
import { Link, useNavigate } from "react-router-dom";
import { productQuantityCartApi } from "../../redux/features/cart/productQuantityCartSlice";
import "./Cart.css";
import { IoShieldCheckmark } from "react-icons/io5";
import { MdDeleteForever, MdVerified } from "react-icons/md";
import { loadCart } from "../../redux/features/cart/getSessionCartSlice";
import { MdAddShoppingCart } from "react-icons/md";
import Breadcrumb from "../../components/Breadcrumb";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Cart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartData, setCartData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    const sessionCart = JSON.parse(sessionStorage.getItem("cart"));
    if (sessionCart) {
      setCartData(sessionCart);
      calculateTotalAmount(sessionCart);
    } else {
      dispatch(getProductCartApi());
    }
  }, [dispatch]);

  const getCartSliceResponse = useSelector(
    (state) => state.getProductCartSlice.data.data
  );

  // console.log("DISHANT CART API==============", getCartSliceResponse);

  useEffect(() => {
    if (getCartSliceResponse?.items) {
      setCartData(getCartSliceResponse.items);
      calculateTotalAmount(getCartSliceResponse.items);
    }
  }, [getCartSliceResponse]);

  const calculateTotalAmount = (cartItems) => {
    const total = cartItems.reduce((sum, item) => {
      const itemPrice =
        item?.productDetails?.productPrice[0]?.finalProductPrice || item.price;
      return sum + itemPrice * item.quantity;
    }, 0);
    setTotalAmount(total);
  };

  const handleRemove = async (id) => {
    if (token) {
      await dispatch(removeProductCartApi(id));
      dispatch(getProductCartApi());
    } else {
      const sessionCart = JSON.parse(sessionStorage.getItem("cart")) || [];
      const updatedCart = sessionCart.filter((item) => item.product_id !== id);
      sessionStorage.setItem("cart", JSON.stringify(updatedCart));
      dispatch(loadCart());
      setCartData(updatedCart);
      calculateTotalAmount(updatedCart); // Recalculate total after removal
    }
  };

  const handleQuantity = (item, productQty) => {
    if (token) {
      // If the user is logged in, update quantity via API
      const payload = {
        newQuantity: productQty,
      };
      dispatch(productQuantityCartApi({ productId: item.product_id, payload }));
      dispatch(getProductCartApi());
    } else {
      // If the user is not logged in, update quantity in session storage
      const sessionCart = JSON.parse(sessionStorage.getItem("cart")) || [];
      const updatedCart = sessionCart.map((cartItem) =>
        cartItem.product_id === item.product_id
          ? { ...cartItem, quantity: parseInt(productQty) }
          : cartItem
      );
      sessionStorage.setItem("cart", JSON.stringify(updatedCart));
      setCartData(updatedCart);
      calculateTotalAmount(updatedCart); // Recalculate total after quantity update
    }
  };

  // console.log("SESSION CART DATA++++++++++++++++++++++++", cartData);

  const handlePayBtn = () => {
    if (token) {
      navigate("/checkout", {
        state: {
          products: cartData,
          totalAmount: totalAmount,
        },
      });
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="universal_container">
      <Breadcrumb currentPageName="Cart" />
      <div
        className={
          cartData && cartData.length > 0 ? "cart-container" : "not-login"
        }
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "20px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#4a2a7d",
            }}
          >
            <h5>Shopping Cart</h5>
            <h5>Total Items: {cartData?.length || "0"}</h5>
          </div>
          <hr />
          {cartData && cartData.length > 0 ? (
            cartData.map((item, i) => (
              <div key={i}>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    padding: "10px",
                    borderRadius: "10px",
                    marginBottom: "20px",
                    color: "#4a2a7d",
                  }}
                >
                  <div style={{ width: "20%" }}>
                    <Link to={`/shop/${item?.id || item?.product_id}`}>
                      <img
                        src={item?.productDetails?.productImage || item?.img}
                        alt="item"
                        width="100%"
                      />
                    </Link>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "start",
                      gap: "20px",
                    }}
                  >
                    <div>
                      <Link to={`/shop/${item?.id || item?.product_id}`}>
                        <h5 className="product_heading">
                          {item?.productDetails?.productName || item.name}
                        </h5>
                      </Link>
                      <h6>
                        Seller: Augmont{" "}
                        <Tooltip title="Verified">
                          <span>
                            <MdVerified color="blue" />
                          </span>
                        </Tooltip>
                      </h6>
                      <h6>
                        Weight: {item?.productDetails?.weight || item.weight}gm
                      </h6>
                      <h5>
                        ₹
                        {item?.productDetails?.productPrice[0]
                          ?.finalProductPrice || item.price}
                      </h5>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <select
                        onChange={(event) =>
                          handleQuantity(item, event.target.value)
                        }
                        value={
                          (item?.productDetails?.productName &&
                            item?.quantity) ||
                          item?.quantity
                        }
                      >
                        {[...Array(10).keys()].map((value) => (
                          <option key={value + 1} value={value + 1}>
                            Qty: {value + 1}
                          </option>
                        ))}
                      </select>

                      <Tooltip title="Delete">
                        <IconButton>
                          <MdDeleteForever
                            color="red"
                            onClick={() => handleRemove(item.product_id)}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                {i !== cartData.length - 1 && <hr />}
              </div>
            ))
          ) : (
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                padding: "3rem 5rem",
              }}
            >
              {/* <img src="./empty-cart.png" alt="empty-cart" width={"50%"} /> */}
              <MdAddShoppingCart size={150} color="#4a2a7d" />
              <h2 style={{ color: "#4a2a7d" }}>Your cart is empty</h2>
            </div>
          )}
        </div>

        {/* PRICE DETAIL===============================================  */}

        {cartData && cartData.length > 0 && (
          <div
            style={
              {
                // position: "fixed",
                // right: 100,
              }
            }
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "20px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderRadius: "10px",
                maxHeight: "330px",
                position: "relative",
                color: "#4a2a7d",
              }}
            >
              <h5>Price Details</h5>
              <hr />
              <p style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Price ({`${cartData?.length} items` || "0"})</div>
                <div>₹{getCartSliceResponse?.totalAmount || totalAmount}</div>
              </p>
              <p style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Delivery Charges</div>
                <div style={{ color: "#388E3C" }}>
                  <s style={{ color: "gray" }}> ₹200</s> Free
                </div>
              </p>
              <hr />
              <p
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "bold",
                }}
              >
                <h5>Total Amount:</h5>
                <h5>₹{getCartSliceResponse?.totalAmount || totalAmount}</h5>
              </p>
              <Button
                variant="contained"
                sx={{
                  position: "absolute",
                  left: "20%",
                  right: "20%",
                  margin: "auto",
                  backgroundColor: "#472878",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#472878",
                  },
                }}
                // fullWidth
                onClick={handlePayBtn}
              >
                Place Order
              </Button>
            </div>
            <div
              style={{
                color: "green",
                marginTop: "40px",
                display: "flex",
                gap: "10px",
              }}
            >
              <IoShieldCheckmark size={40} color="green" />
              <div>
                <div>
                  Safe and Secure Payments. Easy returns. 100% Authentic
                  products.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
