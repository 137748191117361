import "./App.css";
import "./pages/Profile/tab.scss";
import {
  Routes,
  Route,
  useNavigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { useEffect, useState } from "react";
// import Overview from "./pages/Overview";
import Goldify from "./pages/Goldpage/Goldify";
import SellGold from "./pages/Goldpage/sellGold";
import DeliveryGold from "./pages/Goldpage/DeliveryGold";
import Login from "./login";
import Tiless from "./Tiles";
import "sweetalert2/src/sweetalert2.scss";
import HistoryPortfolio from "./pages/history";
import GoldShell from "./pages/Goldpage/goldsellsummary";
import OrderSummary from "./pages/Goldpage/orderSummary";
import Billing from "./pages/Goldpage/Billing-form";
import PanNumber from "./pages/Goldpage/PanCard";
import Invoice from "./pages/Goldpage/invoice";
import Portfolio from "./pages/Portfolio";
import BankDetails from "./pages/Goldpage/bankdetails";
import Silvify from "./pages/Silvify/Silvify";
import SilverOrderSummary from "./pages/Silvify/orderSummary";
import SilverPanNumber from "./pages/Silvify/PanCard";
import SilverBilling from "./pages/Silvify/Billing-form";
import SilverInvoice from "./pages/Silvify/invoice";
import SellSilver from "./pages/Silvify/sellGold";
import DeliverySilver from "./pages/Silvify/DeliveryGold";
import SilverBankDetails from "./pages/Silvify/bankdetails";
import SilverShell from "./pages/Silvify/goldsellsummary";
import Profile from "./pages/Profile/Profile";
import ProfileSetting from "./pages/Profile/ProfileSetting";
import HistoryInvoice from "./pages/historyInvoice";
import Lonify from "./pages/lonify/lonify";
import ApplyLoan from "./pages/lonify/applyLoan";
import GoldSoldInvoice from "./pages/Goldpage/GoldSoldInvoice";
import SilverSoldInvoice from "./pages/Silvify/GoldSoldInvoice";
import Dashboard from "./pages/Dashboard/Dashboard";
import Products from "./pages/products/Products";
import ProductDetails from "./pages/productDetails/ProductDetails";
import ShippingAddress from "./pages/ShippingAddress/ShippingAddress";
import Confirmation from "./pages/Goldpage/Confirmation";
import SpotGold from "./pages/spotGold/SpotGold";
import SpotGoldProductDetails from "./pages/spotGold/SpotGoldProductDetails";
import Address from "./pages/address/Address";
import GoldFD from "./pages/goldFD/GoldFD";
import GoldFDDetails from "./pages/goldFD/GoldFDDetails";
import Home from "./pages/shop/Shop";
import Cart from "./pages/cart/Cart";
import ProductDetail from "./pages/productDetail/ProductDetail";
import Checkout from "./pages/checkout/Checkout";
import Navbar from "./components/Navbar";
import Footer from "./components/footer/Footer";
import { generateToken, messaging } from "./notifications/firebase";
import { onMessage } from "firebase/messaging";
import { toast } from "react-toastify";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const shouldHideNavbar = location.pathname.includes("dashboard");

  const shouldHideFooter =
    location.pathname.includes("dashboard") ||
    location.pathname.includes("account") ||
    location.pathname.includes("login");

  const shopPath = location.pathname.includes("shop");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    generateToken();
    onMessage(messaging, (payload) => {
      console.log(payload);
      toast.success(payload.notification.body);
    });
  }, []);

  return (
    <div
      className={`app_layout ${
        shouldHideNavbar ? "hide-navbar" : "home_app_layout"
      }`}
    >
      <div className={shopPath ? "shop_style" : ""}>
        {!shouldHideNavbar && <Navbar />}
      </div>
      <div>
        <Outlet />
      </div>
      <div>{!shouldHideFooter && <Footer />}</div>
    </div>

    // <Routes>
    //   <Route path="/login" element={<Login />} />
    //   <Route path="/cart" element={<Cart />} />
    //   <Route path="/home" element={<Home />} />
    //   <Route path=":id" element={<ProductDetail />} />
    //   <Route path="/checkout" element={<Checkout />} />
    //   <Route path="/" element={<Home />} />
    //   {isAuthenticated && (
    //     <>
    //       {/* <Route path="/" element={<Tiless />} /> */}
    //       <Route path="/dashboard" element={<Dashboard />}>
    //         <Route index element={<Overview />} />

    //         {/* ------------------------GOLD ROUTES------------------------- */}
    //         <Route path="buygold">
    //           <Route path="" element={<Goldify />} />
    //           <Route path="products">
    //             <Route path="" element={<Products />} />
    //             <Route path="productDetails">
    //               <Route path="" element={<ProductDetails />} />
    //               <Route path="shippingAddress">
    //                 <Route path="" element={<ShippingAddress />} />
    //                 <Route path="confirmation" element={<Confirmation />} />
    //               </Route>
    //             </Route>
    //           </Route>
    //         </Route>

    //         {/* ------------------------SILVER ROUTES------------------------- */}
    //         <Route path="buysilver">
    //           <Route path="" element={<Silvify />} />
    //           <Route path="products">
    //             <Route path="" element={<Products />} />
    //             <Route path="productDetails">
    //               <Route path="" element={<ProductDetails />} />
    //               <Route path="shippingAddress">
    //                 <Route path="" element={<ShippingAddress />} />
    //                 <Route path="confirmation" element={<Confirmation />} />
    //               </Route>
    //             </Route>
    //           </Route>
    //         </Route>

    //         {/* ------------------------SPOT GOLD ROUTES------------------------- */}
    //         <Route path="spotGold">
    //           <Route path="" element={<SpotGold />} />
    //           <Route path=":id">
    //             <Route path="" element={<SpotGoldProductDetails />} />
    //             <Route path="address">
    //               <Route path="" element={<Address />} />
    //               <Route path="confirmation" element={<Confirmation />} />
    //             </Route>
    //           </Route>
    //         </Route>

    //         {/* ------------------------GOLD FD ROUTES------------------------- */}
    //         <Route path="goldFD">
    //           <Route path="" element={<GoldFD />} />
    //           <Route path="goldFD-details" element={<GoldFDDetails />} />
    //         </Route>

    //         {/* <Route path="buysilver" element={<Silvify />} /> */}
    //         <Route path="bankdetails" element={<BankDetails />} />
    //         <Route path="silverbankdetails" element={<SilverBankDetails />} />
    //         <Route path="orderSummary" element={<OrderSummary />} />
    //         <Route path="silverorderSummary" element={<SilverOrderSummary />} />
    //         <Route path="sellGold" element={<SellGold />} />
    //         <Route path="sellsilver" element={<SellSilver />} />
    //         <Route path="pancard" element={<PanNumber />} />
    //         <Route path="silverpancard" element={<SilverPanNumber />} />
    //         <Route path="invoice" element={<Invoice />} />
    //         <Route path="silverinvoice" element={<SilverInvoice />} />
    //         <Route path="billing" element={<Billing />} />
    //         <Route path="silverbilling" element={<SilverBilling />} />
    //         <Route path="history" element={<HistoryPortfolio />} />
    //         <Route path="goldsellsummary" element={<GoldShell />} />
    //         <Route path="silversellsummary" element={<SilverShell />} />
    //         <Route path="portfolio" element={<Portfolio />} />
    //         <Route path="profile" element={<Profile />} />
    //         <Route path="ProfileSetting" element={<ProfileSetting />} />

    //         <Route path="deliverygold" element={<DeliveryGold />} />
    //         <Route path="deliverySilver" element={<DeliverySilver />} />
    //         <Route path="historyinvoice" element={<HistoryInvoice />} />
    //         <Route path="lonify" element={<Lonify />} />
    //         <Route path="applyloan" element={<ApplyLoan />} />
    //         <Route path="overview" element={<Overview />} />
    //         <Route path="goldSoldInvoice" element={<GoldSoldInvoice />} />
    //         <Route path="silverSoldInvoice" element={<SilverSoldInvoice />} />
    //       </Route>
    //     </>
    //   )}
    // </Routes>
  );
}

export default App;

// import "./App.css";
// import "./pages/Profile/tab.scss";
// import { Routes, Route, useNavigate } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle";
// import { useEffect, useState } from "react";
// import Overview from "./pages/Overview";
// import Goldify from "./pages/Goldpage/Goldify";
// import SellGold from "./pages/Goldpage/sellGold";
// import DeliveryGold from "./pages/Goldpage/DeliveryGold";
// import Login from "./login";
// import Tiless from "./Tiles";
// import "sweetalert2/src/sweetalert2.scss";
// import HistoryPortfolio from "./pages/history";
// import GoldShell from "./pages/Goldpage/goldsellsummary";
// import OrderSummary from "./pages/Goldpage/orderSummary";
// import Billing from "./pages/Goldpage/Billing-form";
// import PanNumber from "./pages/Goldpage/PanCard";
// import Invoice from "./pages/Goldpage/invoice";
// import Portfolio from "./pages/Portfolio";
// import BankDetails from "./pages/Goldpage/bankdetails";
// import Silvify from "./pages/Silvify/Silvify";
// import SilverOrderSummary from "./pages/Silvify/orderSummary";
// import SilverPanNumber from "./pages/Silvify/PanCard";
// import SilverBilling from "./pages/Silvify/Billing-form";
// import SilverInvoice from "./pages/Silvify/invoice";
// import SellSilver from "./pages/Silvify/sellGold";
// import DeliverySilver from "./pages/Silvify/DeliveryGold";
// import SilverBankDetails from "./pages/Silvify/bankdetails";
// import SilverShell from "./pages/Silvify/goldsellsummary";
// import Profile from "./pages/Profile/Profile";
// import ProfileSetting from "./pages/Profile/ProfileSetting";
// import HistoryInvoice from "./pages/historyInvoice";
// import Lonify from "./pages/lonify/lonify";
// import ApplyLoan from "./pages/lonify/applyLoan";
// import GoldSoldInvoice from "./pages/Goldpage/GoldSoldInvoice";
// import SilverSoldInvoice from "./pages/Silvify/GoldSoldInvoice";
// import Dashboard from "./pages/Dashboard/Dashboard";

// function App() {
//   const navigate = useNavigate();
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {
//     const token = sessionStorage.getItem("token");
//     if (!token) {
//       navigate("/");
//     } else {
//       setIsAuthenticated(true);
//     }
//   }, [navigate]);

//   return (
//     <Routes>
//       <Route path="/" element={<Login />} />
//       {isAuthenticated && (
//         <>
//           <Route path="/tiles" element={<Tiless />} />
//           <Route path="/dashboard" element={<Dashboard />}>
//             <Route index element={<Overview />} />
//             <Route path="buygold" element={<Goldify />} />
//             <Route path="buysilver" element={<Silvify />} />
//             <Route path="bankdetails" element={<BankDetails />} />
//             <Route path="silverbankdetails" element={<SilverBankDetails />} />
//             <Route path="orderSummary" element={<OrderSummary />} />
//             <Route path="silverorderSummary" element={<SilverOrderSummary />} />
//             <Route path="sellGold" element={<SellGold />} />
//             <Route path="sellsilver" element={<SellSilver />} />
//             <Route path="pancard" element={<PanNumber />} />
//             <Route path="silverpancard" element={<SilverPanNumber />} />
//             <Route path="invoice" element={<Invoice />} />
//             <Route path="silverinvoice" element={<SilverInvoice />} />
//             <Route path="billing" element={<Billing />} />
//             <Route path="silverbilling" element={<SilverBilling />} />
//             <Route path="history" element={<HistoryPortfolio />} />
//             <Route path="goldsellsummary" element={<GoldShell />} />
//             <Route path="silversellsummary" element={<SilverShell />} />
//             <Route path="portfolio" element={<Portfolio />} />
//             <Route path="profile" element={<Profile />} />
//             <Route path="ProfileSetting" element={<ProfileSetting />} />
//             <Route path="deliverygold" element={<DeliveryGold />} />
//             <Route path="deliverySilver" element={<DeliverySilver />} />
//             <Route path="historyinvoice" element={<HistoryInvoice />} />
//             <Route path="lonify" element={<Lonify />} />
//             <Route path="applyloan" element={<ApplyLoan />} />
//             <Route path="overview" element={<Overview />} />
//             <Route path="goldSoldInvoice" element={<GoldSoldInvoice />} />
//             <Route path="silverSoldInvoice" element={<SilverSoldInvoice />} />
//           </Route>
//         </>
//       )}
//     </Routes>
//   );
// }

// export default App;

// import logo from "./logo.svg";
// import "./App.css";
// import "./pages/Profile/tab.scss";
// import { Routes, Route, useNavigate } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle";
// import { useCallback, useEffect, useState } from "react";
// import axios from "axios";
// import "react-date-picker/dist/DatePicker.css";
// import "react-calendar/dist/Calendar.css";
// import Overview from "./pages/Overview";
// import Goldify from "./pages/Goldpage/Goldify";
// // import Silvify from "./pages/Silvify";
// // import Lonify from "./pages/Lonify";
// // import BuyGold from "./pages/BuyGold";
// import SellGold from "./pages/Goldpage/sellGold";
// import DeliveryGold from "./pages/Goldpage/DeliveryGold";
// import Login from "./login";
// import Tiless from "./Tiles";
// import CoinDetial from "./pages/Goldpage/CoinBuy";
// import "sweetalert2/src/sweetalert2.scss";
// import HistoryPortfolio from "./pages/history";
// // import DeliverySilver from "./pages/SilverPage/DeliverySilver";
// import GoldShell from "./pages/Goldpage/goldsellsummary";
// import OrderSummary from "./pages/Goldpage/orderSummary";
// import Billing from "./pages/Goldpage/Billing-form";
// import PanNumber from "./pages/Goldpage/PanCard";
// import Invoice from "./pages/Goldpage/invoice";
// import Portfolio from "./pages/Portfolio";
// import BankDetails from "./pages/Goldpage/bankdetails";
// import Silvify from "./pages/Silvify/Silvify";
// import SilverOrderSummary from "./pages/Silvify/orderSummary";
// import SilverPanNumber from "./pages/Silvify/PanCard";
// import SilverBilling from "./pages/Silvify/Billing-form";
// import SilverInvoice from "./pages/Silvify/invoice";
// import SellSilver from "./pages/Silvify/sellGold";
// import DeliverySilver from "./pages/Silvify/DeliveryGold";
// import SilverBankDetails from "./pages/Silvify/bankdetails";
// import SilverShell from "./pages/Silvify/goldsellsummary";
// import Profile from "./pages/Profile/Profile";
// import ProfileSetting from "./pages/Profile/ProfileSetting";
// import Login2 from "./Login2";
// // import Gainify from "./pages/gainify/Ganify";
// // import Invest from "./pages/gainify/Invest";
// import HistoryInvoice from "./pages/historyInvoice";
// import lonify from "./pages/lonify/lonify";
// import Lonify from "./pages/lonify/lonify";
// import ApplyLoan from "./pages/lonify/applyLoan";
// import GoldSoldInvoice from "./pages/Goldpage/GoldSoldInvoice";
// import SilverSoldInvoice from "./pages/Silvify/GoldSoldInvoice";
// // import Dashboard from "./pages/Dashboard";
// import Dashboard from "./pages/Dashboard/Dashboard";
// function App() {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     if (!token) {
//       navigate("/");
//     }
//   }, []);

//   return (
//     <Routes>
//       {/* <Route path="/" element={<Login2 />} /> */}
//       <Route path="/" element={<Login />} />

//       <Route path="/tiles" element={<Tiless />} />

//       {/* <Route path="/dashboard" element={<Dashboard />}> */}
//       <Route path="/dashboard" element={<Dashboard />}>
//         <Route index element={<Overview />} />
//         <Route path="buygold" element={<Goldify />} />
//         <Route path="buysilver" element={<Silvify />} />
//         {/* <Route path="gainify" element={<Gainify />} /> */}

//         <Route path="bankdetails" element={<BankDetails />} />
//         <Route path="silverbankdetails" element={<SilverBankDetails />} />
//         <Route path="orderSummary" element={<OrderSummary />} />
//         <Route path="silverorderSummary" element={<SilverOrderSummary />} />
//         <Route path="sellGold" element={<SellGold />} />
//         <Route path="sellsilver" element={<SellSilver />} />
//         <Route path="pancard" element={<PanNumber />} />
//         <Route path="silverpancard" element={<SilverPanNumber />} />
//         <Route path="invoice" element={<Invoice />} />
//         <Route path="silverinvoice" element={<SilverInvoice />} />
//         <Route path="billing" element={<Billing />} />
//         <Route path="silverbilling" element={<SilverBilling />} />
//         <Route path="history" element={<HistoryPortfolio />} />
//         <Route path="goldsellsummary" element={<GoldShell />} />
//         <Route path="silversellsummary" element={<SilverShell />} />
//         <Route path="portfolio" element={<Portfolio />} />
//         <Route path="profile" element={<Profile />} />
//         <Route path="ProfileSetting" element={<ProfileSetting />} />
//         {/* <Route path="chargers" element={<Silvify />} /> */}
//         {/* <Route path="goldify" element={<Goldify />} /> */}
//         {/* <Route path="sellgold" element={< SellGold />} /> */}
//         <Route path="deliverygold" element={<DeliveryGold />} />
//         <Route path="deliverySilver" element={<DeliverySilver />} />
//         {/* <Route path="invest" element={<Invest />} /> */}
//         <Route path="historyinvoice" element={<HistoryInvoice />} />
//         <Route path="lonify" element={<Lonify />} />
//         <Route path="applyloan" element={<ApplyLoan />} />

//         {/* <Route path="deliverySilver" element={<DeliverySilver />} /> */}

//         <Route path="overview" element={<Overview />} />
//         <Route path="goldSoldInvoice" element={<GoldSoldInvoice />} />
//         <Route path="silverSoldInvoice" element={<SilverSoldInvoice />} />
//       </Route>
//     </Routes>
//   );
// }

// export default App;
