import React, { useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useCountdown } from "react-countdown-circle-timer";
import axios from "axios";
// const Swal = require('sweetalert2')
import Swal from "sweetalert2";
import { GoArrowLeft } from "react-icons/go";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer.jsx";
import Loader from "../../components/Loader.jsx";
import { Button } from "@mui/material";

function SilverOrderSummary() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  const { t } = useTranslation();
  const {
    orderSummary,
    gst,
    totalAmt,
    priceExpire,
    bbpl,
    line1,
    line2,
    line3,
    line4,
    termsAndConditions,
    buyBtn,
  } = t("goldOrderSummaryPage");

  const { silverWeight, silverRate, silverValue } = t(
    "goldOrderSummaryPage.silverSummary"
  );

  const [isPanBAddress, setisPanBAddress] = useState(null);
  const Token = sessionStorage.getItem("token");
  // console.log("login token in inbox", Token);

  var buydata = "";
  let transec = "";

  const navigate = useNavigate();
  const location = useLocation();

  const data = location.state;
  // console.log("ordersummary data", data);

  const Goldweight = data.data.data[0].totalQuantity;
  const Goldrate = data.data.data[0].pricePerGram;
  const GoldValue = data.data.data[0].amount;
  const GST = data.data.data[0].gst;
  const TotalAmount = data.data.data[0].amountAfterTax;
  const mtd = data.data.data[0].merchantTransactionId;
  const mt = data.data.data[0].metalType;
  const mClick = data.clickFirst;
  const [transactionId, settransactionId] = useState("");
  const [amount, setamount] = useState("");

  // ----------------timer--------------
  const {
    path,
    pathLength,
    stroke,
    strokeDashoffset,
    remainingTime,
    elapsedTime,
    size,
    strokeWidth,
  } = useCountdown({ isPlaying: true, duration: 7, colors: "#abc" });
  // -----------------------------------------------

  const [pannum, setpanum] = useState("");
  const [billing, setbilling] = useState("");
  const [razorpayResponse, setRazorpayResponse] = useState(null);
  // const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const renderTime = ({ remainingTime }) => {
    return <div>{remainingTime}</div>;
  };

  //
  useEffect(() => {
    GetProfileAPI();
  }, []);

  // ------------------------get Profile for KYC ------------------------------------
  const [profileApiData, setProfileApiData] = useState("");

  const GetProfileAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/getProfile`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      setProfileApiData(response?.data?.data[0]);
      const Profiledata = response.data;
      let PanNumber = Profiledata.data[0].kyc.pan_no;
      setbilling(Profiledata.data[0].hasBillingaddress_id);
      setpanum(PanNumber);

      Profiledata.data?.map((item) => {
        setpanum(item.pan_number);
        if (item.pan_number !== "" && item.hasBillingaddress_id !== false) {
          setisPanBAddress(true);
        } else {
          setisPanBAddress(false);
        }
      });
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };
  // -----------------------end ------------------------------------------

  // ------------------------- pan check -----------------------------

  const PanvalidRedirect = () => {
    if (pannum === null) {
      // handlePayment();
      navigate("/dashboard/pancard");
    } else if (pannum !== null && billing === false) {
      navigate("/dashboard/billing");
    } else {
      handlePayment();
      // navigate('/dashboard/pancard');
    }
  };

  // -----------------------------------------------------------------

  const callGetBuyAPI = async () => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/augmont/buy`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`, // Replace with your actual token
      };

      const requestBody = {
        merchantTransactionId: mtd,
        metalType: mt,
      };

      if (mClick === "A") {
        requestBody["amount"] = TotalAmount;
      } else if (mClick === "Q") {
        requestBody["quantity"] = Goldweight;
      } else {
        requestBody["amount"] = TotalAmount;
      }

      const response = await axios.post(url, requestBody, { headers });
      setLoading(false);
      const data = response.data;
      // console.log("BuyGet after success Api response data is=", data);
      // console.log("transtion new", data.data[0].result.data.transactionId)
      // console.log("transtion new", data.data[0].result.data.totalAmount)

      return data;
    } catch (error) {
      setLoading(false);
      // console.erro / r("Error connecting to API", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleOpenRazorpay = async (data) => {
    try {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: Number(data.amount),
        currency: data.currency,
        name: "Bharat Batuk Pvt.ltd.",
        description: "XYZ",
        order_id: data.id,
        handler: function (response) {
          setRazorpayResponse(response);
          callGetBuyAPI()
            .then((buydata) => {
              console.log("buy data 2", buydata);
              settransactionId(buydata.data[0].result.data.transactionId);
              setamount(buydata.data[0].result.data.totalAmount);
              Swal.fire({
                title: "Payment Successful",
                html: `
                            Transaction Number: ${buydata.data[0].result.data.transactionId}<br/><br/>
                             Amount:${buydata.data[0].result.data.totalAmount}
                          `,

                icon: "success",
              }).then((d) => {
                if (d.isConfirmed === true) {
                  navigate("/dashboard/silverinvoice", {
                    state: {
                      buydata: buydata,
                      response: response, // Pass response data to the invoice page
                    },
                  });
                }
              });
            })
            .catch((error) => {
              console.log("error", error);
            });
        },
      };
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error handling Razorpay", error);
      throw error;
    }
  };

  const handlePayment = async () => {
    try {
      const _data = { TotalAmount: TotalAmount };
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/orders`,
        _data
      );
      await handleOpenRazorpay(res.data.data);
    } catch (error) {
      console.log("Error handling payment", error);
    }
  };
  useEffect(() => {
    GetProfileAPI();
  }, []);

  // ------------------------get Profile for KYC ------------------------------------
  //   const GetProfileAPI = async () => {
  //     try {
  //       const url = `${process.env.REACT_APP_BASE_URL}/getProfile`;
  //       const headers = {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         Authorization: `Bearer ${Token}`,
  //       };
  //       const response = await axios.get(url, { headers });
  //         const Profiledata = response.data;
  //         console.log("Profiledata",Profiledata)
  //       Profiledata.data?.map((item) => {
  //           console.log("item of billing address from profile", item);
  //           setpanum(item.pan_number);
  //           console.log("pan number sandy sir", item.pan_number);
  //           if (item.pan_number !== "" && item.hasBillingaddress_id !== false) {
  //             setisPanBAddress(true);
  //           }  else {
  //             setisPanBAddress(false);
  //           }
  //       });
  //       console.log("Get Profile API Response", Profiledata);
  //     //   let PanNumber = Profiledata.data[0].pan_number;
  //     //     let billingAddressId = Profiledata.data[0].hasBillingaddress_id;
  //     //     console.log("PanNumber",PanNumber);
  //     //     console.log("billingAddressId",billingAddressId);
  //     //   if (PanNumber !== "" && billingAddressId !== false) {
  //     //     setisPanBAddress(true);
  //     //   }  else {
  //     //     setisPanBAddress(false);
  //     //   }

  //     } catch (error) {
  //       console.error("Error connecting to API in getprofile function", error);
  //     }
  //   };

  //   ------------------------end ----------------------------
  // ----------------------------------buy api final on response payment gateway-------------------------

  return (
    <>
      <div className="container-fluid" style={{ height: "auto" }}>
        {loading ? (
          <Loader />
        ) : (
          <div className="row d-flex justify-content-between">
            {/* <div className="col-md-2"></div> */}

            <div
              // className="col-md-6  "
              style={{
                height: "auto",
                borderRadius: "10px",
                boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)",
              }}
            >
              <h2
                className="m-2 fw-bold text-center mb-3"
                style={{ color: "#4A2A7D" }}
              >
                {orderSummary}
              </h2>

              <div className="row">
                <table className="table table-borderless">
                  <tbody style={{ color: "#4A2A7D" }}>
                    <tr>
                      <td></td>
                      <td
                        style={{ color: "#4A2A7D", fontSize: "20px" }}
                        className=" fs-5"
                      >
                        {silverWeight}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        style={{ color: "#4A2A7D", fontSize: "20px" }}
                        className=" fs-5"
                      >
                        {Goldweight}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td
                        style={{ color: "#4A2A7D", fontSize: "20px" }}
                        className=" fs-5"
                      >
                        {silverRate}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        style={{ color: "#4A2A7D", fontSize: "20px" }}
                        className=" fs-5"
                      >
                        {Goldrate}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td
                        style={{ color: "#4A2A7D", fontSize: "20px" }}
                        className=" fs-5"
                      >
                        {silverValue} {Goldweight}/gm (&#8377;)
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        style={{ color: "#4A2A7D", fontSize: "20px" }}
                        className=" fs-5"
                      >
                        {GoldValue}
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td
                        style={{ color: "#4A2A7D", fontSize: "20px" }}
                        className=" fs-5"
                      >
                        GST (&#8377;)
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        style={{ color: "#4A2A7D", fontSize: "20px" }}
                        className=" fs-5"
                      >
                        {GST}
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td className="fw-bold fs-5" style={{ color: "#880E4F" }}>
                        {totalAmt} (&#8377;)
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="fw-bold fs-5" style={{ color: "#880E4F" }}>
                        {TotalAmount}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                className="d-flex justify-content-center mb-3"
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  display: "flex",
                  gap: "5px",
                }}
              >
                <span className=" mt-3 mr-3 h5" style={{ color: "#4A2A7D" }}>
                  {priceExpire}
                </span>
                <CountdownCircleTimer
                  isPlaying
                  size={60}
                  strokeWidth={10}
                  duration={180}
                  colors={["#4A2A7D", "#4A2A7D", "#4A2A7D", "#4A2A7D"]}
                  colorsTime={[180, 120, 60, 0]}
                  onComplete={() => ({ shouldRepeat: true, delay: 1 })}
                >
                  {renderTime}
                </CountdownCircleTimer>
              </div>

              <Footer />

              {/* <div className="d-flex justify-content-center mt-4 mb-2 ">
                {isPanBAddress ? (
                  <button
                    type="button"
                    className="btn p-2 text-light"
                    onClick={() => PanvalidRedirect()}
                    style={{
                      // width: "300px",
                      borderRadius: "10px",
                      fontSize: "20px",
                      backgroundColor: "#4A2A7D",
                    }}
                  >
                    {buyBtn}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn p-3 text-light"
                    onClick={() => PanvalidRedirect()}
                    style={{
                      // width: "300px",
                      borderRadius: "20px",
                      fontSize: "20px",
                      backgroundColor: "#4A2A7D",
                    }}
                  >
                    Complete KYC
                  </button>
                )}
              </div> */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "2rem auto",
                }}
              >
                {profileApiData?.kyc_status == 1 &&
                profileApiData?.billAddressUser != null ? (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#4a2a7d",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#4a2a7d",
                      },
                    }}
                    onClick={handlePayment}
                  >
                    Buy now
                  </Button>
                ) : (
                  <Link to="/dashboard/ProfileSetting">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#4a2a7d",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#4a2a7d",
                        },
                      }}
                    >
                      Complete KYC
                    </Button>
                  </Link>
                )}
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        )}
      </div>
    </>
  );
}

export default SilverOrderSummary;
