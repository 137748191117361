import React, { useState, useEffect } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { GoArrowLeft } from "react-icons/go";
import { toast } from "react-toastify";
import Footer from "../../components/Footer.jsx";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import goldAndSilverSellSlice from "../../redux/features/goldAndSilver/goldAndSilverSellSlice.js";
import Loader from "../../components/Loader.jsx";
import { Button } from "@mui/material";
const Swal = require("sweetalert2");

function GoldShell() {
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   const login = sessionStorage.getItem("token");
  //   if (!login) {
  //     navigate("/");
  //   }
  // }, []);

  const { t } = useTranslation();
  const { orderSummary, totalAmt, priceExpire } = t("goldOrderSummaryPage");
  const { goldWeight, goldRate } = t("goldOrderSummaryPage.goldSummary");

  const { sellBtn } = t("goldSellSummaryPage");

  const [acc, setAcc] = useState("");
  const data = location.state;
  const {
    totalQuantity,
    pricePerGram,
    amount,
    amountAfterTax,
    merchantTransactionId,
    metalType,
  } = location.state || "";

  const Goldweight = data.data.data[0].totalQuantity;
  const Goldrate = data.data.data[0].pricePerGram;
  const GoldValue = data.data.data[0].amount;
  const GST = data.data.data[0].gst;
  const TotalAmount = data.data.data[0].amountAfterTax;
  const mtd = data.data.data[0].merchantTransactionId;
  const mt = data.data.data[0].metalType;
  const mClick = data.clickFirst;

  const Token = sessionStorage.getItem("token");

  const [loading, setLoading] = useState(false);

  const callGetBuyAPI = async () => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/augmont/sell-gold-silver`;

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const requestBody = {
        metalType: mt,
        quantity: Goldweight,
      };

      if (mClick === "A") {
        requestBody["amount"] = TotalAmount;
      } else if (mClick === "Q") {
        requestBody["quantity"] = Goldweight;
      } else {
        requestBody["amount"] = TotalAmount;
      }

      const response = await axios.post(url, requestBody, { headers });
      setLoading(false);
      const transactionId =
        response?.data?.data[0]?.result?.data?.transactionId;

      Swal.fire({
        title: "Sold Successful",
        html: `
        Transaction Number: ${transactionId}<br/><br/>
        Amount: ${TotalAmount}
      `,
        icon: "success",
        confirmButtonColor: "#4A2A7D",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      }).then((d) => {
        navigate("/dashboard/goldSoldInvoice", {
          state: {
            rateofgold: Goldrate,
            Goldweight,
            GST,
            Totalamount: TotalAmount,
            transectionID: transactionId,
            UniqueId: mtd,
            inoviceno: mt,
            invoicepath: response.data.data[0].invoicePath,
          },
        });
      });
    } catch (error) {
      setLoading(false);
      console.log("ERROR IN CATCH++++++++++++", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetProfileAPI();
  }, []);

  const [profileApiData, setProfileApiData] = useState("");

  const GetProfileAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/getProfile`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      setProfileApiData(response?.data?.data[0]);

      const accounttt = response.data.data[0].kyc.account_no;
      setAcc(accounttt);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  // const dispatch = useDispatch();

  const goto = () => {
    if (acc === null) {
      navigate("/dashboard/bankdetails");
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4A2A7D",
        cancelButtonColor: "#d33",
        confirmButtonText: "Proceed to sell !",
      })
        .then((d) => {
          if (d.isConfirmed === true) {
            callGetBuyAPI();
            // const payload = {
            //   metalType: mt,
            //   quantity: Goldweight,
            // };

            // dispatch(goldAndSilverSellSlice(payload));
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const handlePayment = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
      confirmButtonText: "Proceed to sell !",
    })
      .then((d) => {
        if (d.isConfirmed === true) {
          callGetBuyAPI();
          // const payload = {
          //   metalType: mt,
          //   quantity: Goldweight,
          // };

          // dispatch(goldAndSilverSellSlice(payload));
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const renderTime = ({ remainingTime }) => {
    return <div>{remainingTime}</div>;
  };

  const sellApiLoading = useSelector(
    (state) => state.goldAndSilverSellSlice.status
  );
  const sellApiResponse = useSelector(
    (state) => state.goldAndSilverSellSlice.data
  );

  return (
    <div style={{ height: "auto", width: "100%" }}>
      {loading ? (
        <Loader />
      ) : (
        <div className="row d-flex justify-content-between mt-0">
          <div
            style={{
              height: "auto",
              borderRadius: "10px",
              boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)",
            }}
          >
            <h2
              className="m-2 fw-bold text-center mb-3"
              style={{ color: "#4A2A7D" }}
            >
              {orderSummary}
            </h2>

            <div className="row">
              <table className="table table-borderless">
                <tbody style={{ color: "#4A2A7D" }}>
                  <tr>
                    <td></td>
                    <td
                      style={{ color: "#4A2A7D", fontSize: "20px" }}
                      className=" fs-5"
                    >
                      {goldWeight}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{ color: "#4A2A7D", fontSize: "20px" }}
                      className=" fs-5"
                    >
                      {Goldweight}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td
                      style={{ color: "#4A2A7D", fontSize: "20px" }}
                      className=" fs-5"
                    >
                      {" "}
                      {goldRate}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{ color: "#4A2A7D", fontSize: "20px" }}
                      className=" fs-5"
                    >
                      {Goldrate}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td
                      style={{ color: "#4A2A7D", fontSize: "20px" }}
                      className=" fs-5"
                    >
                      Gold Value Of {Goldweight} /gm (&#8377;)
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{ color: "#4A2A7D", fontSize: "20px" }}
                      className=" fs-5"
                    >
                      {TotalAmount}
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td className="fw-bold fs-5" style={{ color: "#880E4F" }}>
                      {totalAmt} (&#8377;)
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="fw-bold fs-5" style={{ color: "#880E4F" }}>
                      {TotalAmount}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              className="d-flex justify-content-center mb-3"
              style={{
                alignItems: "center",
                textAlign: "center",
                display: "flex",
                gap: "5px",
              }}
            >
              <span className=" mt-2 mr-3 h5" style={{ color: "#4A2A7D" }}>
                {priceExpire}
              </span>
              <CountdownCircleTimer
                isPlaying
                size={60}
                strokeWidth={10}
                duration={180}
                colors={["#4A2A7D", "#4A2A7D", "#4A2A7D", "#4A2A7D"]}
                colorsTime={[180, 120, 60, 0]}
                onComplete={() => ({ shouldRepeat: true, delay: 1 })}
              >
                {renderTime}
              </CountdownCircleTimer>
            </div>
            <Footer />
            {/* <div className="d-flex justify-content-center mt-4 mb-2 ">
              <button
                type="button"
                onClick={() => goto()}
                style={{
                  borderRadius: "20px",
                  fontSize: "20px",
                  backgroundColor: "#4A2A7D",
                  color: "white",
                  padding: "5px 50px",
                }}
              >
                {sellBtn}
              </button>
            </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "2rem auto",
              }}
            >
              {profileApiData?.kyc_status == 1 &&
              profileApiData?.kyc.account_no ? (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#4a2a7d",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#4a2a7d",
                    },
                  }}
                  onClick={handlePayment}
                >
                  Sell now
                </Button>
              ) : (
                <Link to="/dashboard/ProfileSetting">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#4a2a7d",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#4a2a7d",
                      },
                    }}
                  >
                    Complete KYC
                  </Button>
                </Link>
              )}
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      )}
    </div>
  );
}

export default GoldShell;
