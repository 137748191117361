import { useEffect, useState } from "react";
import { MdOutlineTimer } from "react-icons/md";
import "./BuySell.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const BuySell = ({
  metalType,
  goldApi,
  transactionType,
  goldApiData,
  portfolioApiData,
  callQuantityAPI,
  userAmt,
}) => {
  // console.log("userAmt+=======", userAmt);
  const [activeAmount, setActiveAmount] = useState(null);
  const [isGoldSelected, setIsGoldSelected] = useState(true);
  const [isRupeeSelected, setIsRupeeSelected] = useState(true);
  const [rupeeValue, setRupeeValue] = useState(userAmt || "");
  const [gramValue, setGramValue] = useState("");
  // const [goldPrice, setGoldPrice] = useState("");
  // const [silverPrice, setSilverPrice] = useState("");
  const [timer, setTimer] = useState(180); // 3 minutes in seconds

  const { t } = useTranslation();

  const {
    inRupee,
    inGram,
    proceedToPay,
    quickBuy,
    inputAmountPlaceholder,
    inputWeightPlaceholder,
  } = t("buySellPage");
  const { gold, goldPrice, pureGold, goldExpire, goldInLocker } =
    t("buySellPage.gold");
  const { silver, silverPrice, pureSilver, silverExpire, silverInLocker } =
    t("buySellPage.silver");

  const handleMetalButtonClick = (isGold) => {
    setIsGoldSelected(isGold);
  };

  const handleUnitButtonClick = (isRupee) => {
    setIsRupeeSelected(isRupee);
  };

  const metal = isGoldSelected ? "GOLD" : "SILVER";
  const metalPrice = isGoldSelected ? "₹7000/gm" : "₹1000/gm";
  const metalImage = isGoldSelected ? "/gold.png" : "/silver.png";
  const unit = isRupeeSelected ? "₹" : "gm";

  // console.log("gold API++++++++++++++++++++++",goldApiData)
  // console.log("gold PRICE++++++++++++++++++++++",goldApiData.data.data[0][0].gBuy)
  // console.log("gold PRICE++++++++++++++++++++++",goldApiData.data.data[0][0].sBuy)
  // console.log("PORTFOLIO API++++++++++++++++++++",portfolioApiData)
  // console.log("PORTFOLIO Dishant++++++++++++++++++++",portfolioApiData?.data[0]?.augmont?.gold?.balQuantity)

  const handleInputChange = (e) => {
    const value = e.target.value;
    console.log(goldApiData, transactionType, "opppmmmmmmmmmmmmmm", value);
    console.log(goldApiData?.data.data[0][0].gSell);
    const metal_type = metalType == "GOLD" ? "GOLD" : "SILVER";
    if (isRupeeSelected) {
      setRupeeValue(value);
      let check = transactionType;
      if (metal_type == "GOLD") {
        let livePrice =
          transactionType == "goldBuy"
            ? goldApiData?.data.data[0][0].gBuy
            : goldApiData?.data.data[0][0].gSell;
        let final = (value / livePrice).toFixed(4);
        setGramValue(final);
      } else {
        let livePrice =
          transactionType == "silverBuy"
            ? goldApiData?.data.data[0][0].sBuy
            : goldApiData?.data.data[0][0].sSell;
        let final = (value / livePrice).toFixed(4);
        setGramValue(final);
      }
    } else {
      setGramValue(value);
      if (metal_type == "GOLD") {
        let livePrice =
          transactionType == "goldBuy"
            ? goldApiData?.data.data[0][0].gBuy
            : goldApiData?.data.data[0][0].gSell;
        let final = (value * livePrice).toFixed(4);
        setRupeeValue(final);
      } else {
        let livePrice =
          transactionType == "silverBuy"
            ? goldApiData?.data.data[0][0].sBuy
            : goldApiData?.data.data[0][0].sSell;
        let final = (value * livePrice).toFixed(4);
        setRupeeValue(final);
      }
    }
  };

  // const handleInputChange = (e) => {
  //   const value = e.target.value;
  //   if (isRupeeSelected) {
  //     setRupeeValue(value);
  //     setGramValue(
  //       value
  //         ? (
  //             value /
  //             (metalType == "GOLD"
  //               ? goldApiData.data.data[0][0].gBuy
  //               : goldApiData.data.data[0][0].sBuy)
  //           ).toFixed(4)
  //         : ""
  //     );
  //   } else {
  //     setGramValue(value);
  //     setRupeeValue(
  //       value
  //         ? (
  //             value *
  //             (metalType == "GOLD"
  //               ? goldApiData.data.data[0][0].gBuy
  //               : goldApiData.data.data[0][0].sBuy)
  //           ).toFixed(2)
  //         : ""
  //     );
  //   }
  // };

  const handleAmountButtonClick = (amount) => {
    setActiveAmount(amount);
    const value = amount.replace("₹", "");
    const metal_type = metalType == "GOLD" ? "GOLD" : "SILVER";
    if (isRupeeSelected) {
      setRupeeValue(value);
      let check = transactionType;
      if (metal_type == "GOLD") {
        let livePrice =
          transactionType == "goldBuy"
            ? goldApiData?.data.data[0][0].gBuy
            : goldApiData?.data.data[0][0].gSell;
        let final = (value / livePrice).toFixed(4);
        setGramValue(final);
      } else {
        let livePrice =
          transactionType == "silverBuy"
            ? goldApiData?.data.data[0][0].sBuy
            : goldApiData?.data.data[0][0].sSell;
        let final = (value / livePrice).toFixed(4);
        setGramValue(final);
      }
    } else {
      setGramValue(value);
      if (metal_type == "GOLD") {
        let livePrice =
          transactionType == "goldBuy"
            ? goldApiData?.data.data[0][0].gBuy
            : goldApiData?.data.data[0][0].gSell;
        let final = (value * livePrice).toFixed(4);
        setRupeeValue(final);
      } else {
        let livePrice =
          transactionType == "silverBuy"
            ? goldApiData?.data.data[0][0].sBuy
            : goldApiData?.data.data[0][0].sSell;
        let final = (value * livePrice).toFixed(4);
        setRupeeValue(final);
      }
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(
    //   "API RESPONSE+++++++++++++",
    //   portfolioApiData?.data[0]?.augmont?.silver?.balQuantity
    // );

    // console.log("GRAM VALUES++++++++++++++++++", gramValue);

    // if (transactionType === "goldSell") {
    //   if (gramValue > portfolioApiData?.data[0]?.augmont?.gold?.balQuantity) {
    //     toast.error("you can't sell more than in locker", {
    //       position: "top-center",
    //     });
    //     return;
    //   }
    // }

    if (transactionType === "goldSell") {
      // console.log("gramValue::::::::::::", gramValue);
      const hiiiii = portfolioApiData?.data[0]?.augmont?.gold?.balQuantity;
      const conditionCheck = Number(gramValue) > Number(hiiiii);
      // console.log("gramValue::::::::::::", conditionCheck);

      if (conditionCheck) {
        // console.log("INSIDE SILVER second");
        toast.error("you can't sell more than in locker", {
          position: "top-center",
        });
        return;
      }
    }


    if (transactionType === "silverSell") {
      // console.log("gramValue::::::::::::", gramValue);
      const hiiiii = portfolioApiData?.data[0]?.augmont?.silver?.balQuantity;
      const conditionCheck = Number(gramValue) > Number(hiiiii);
      // console.log("gramValue::::::::::::", conditionCheck);

      if (conditionCheck) {
        // console.log("INSIDE SILVER second");
        toast.error("you can't sell more than in locker", {
          position: "top-center",
        });
        return;
      }
    }

    // if (transactionType === "goldSell" || transactionType === "silverSell") {
    //   if (gramValue > portfolioApiData?.data[0]?.augmont?.gold?.balQuantity) {
    //     toast.error("you can't sell more than in locker", {
    //       position: "top-center",
    //     });
    //     return;
    //   }
    // }
    callQuantityAPI(rupeeValue, gramValue, isRupeeSelected);
  };

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timer]);

  useEffect(() => {
    goldApi();
  }, [timer == 0]);

  return (
    <>
      <div className="bordered-div">
        <div className="inner-container">
          <div className="inner-div">
            <button className="btn-ir card-btn">
              {metalType == "GOLD" ? gold : silver}
            </button>
            <h5 style={{ color: "#472878" }}>
              {metalType == "GOLD" ? goldPrice : silverPrice}
            </h5>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p className="medium-text">
                {transactionType === "goldBuy" ? (
                  <span
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "#FFDF00",
                    }}
                  >
                    ₹ {goldApiData?.data?.data[0][0]?.gBuy}/gm
                  </span>
                ) : transactionType === "goldSell" ? (
                  <span
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "#FFDF00",
                    }}
                  >
                    ₹ {goldApiData?.data?.data[0][0]?.gSell}/gm
                  </span>
                ) : transactionType === "silverBuy" ? (
                  <span
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "#A6A6A6",
                    }}
                  >
                    ₹ {goldApiData?.data?.data[0][0]?.sBuy}/gm
                  </span>
                ) : transactionType === "silverSell" ? (
                  <span
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "#A6A6A6",
                    }}
                  >
                    ₹ {goldApiData?.data?.data[0][0]?.sSell}/gm
                  </span>
                ) : (
                  <span>Invalid transaction type</span>
                )}
              </p>
              <p style={{ color: metalType == "SILVER" ? "#A6A6A6" : "gold" }}>
                +3% GST
              </p>
            </div>
            <p className="" style={{ color: "#472878" }}>
              {metalType == "GOLD" ? pureGold : pureSilver}
            </p>
          </div>
          <div className="inner-div hide-text">Div 2</div>
          <div className="inner-div">
            <img
              src={
                metalType == "GOLD" ? "/gold_shield.png" : "/silver_shield.png"
              }
              alt={`${metal} image`}
              style={{ width: "35%", marginBottom: "1rem" }}
              className="coin-img"
            />
            <div
              className="timer"
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "5px",
                color: "#472878",
              }}
            >
              <MdOutlineTimer />
              <div className="">
                {metalType == "GOLD" ? goldExpire : silverExpire}
              </div>
              <div className="">{formatTime(timer)}</div>
            </div>
          </div>
        </div>

        <div className="button-row-bottom">
          <div className="irg">
            <button
              className={`btn-ir ir-btn ${isRupeeSelected ? "active" : ""}`}
              onClick={() => handleUnitButtonClick(true)}
            >
              {inRupee}
            </button>
            <button
              className={`btn-ir ig-btn ${!isRupeeSelected ? "active" : ""}`}
              onClick={() => handleUnitButtonClick(false)}
            >
              {inGram}
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <div
              className={
                metalType == "GOLD" ? "input-border" : "input_border_silver"
              }
            >
              <div className="input-1">
                <span
                  className="rupee"
                  style={{
                    color: metalType == "SILVER" ? "#A6A6A6" : "#FFDF00",
                    margin: "0px 10px",
                  }}
                >
                  {unit}
                </span>
              </div>
              <input
                type="text"
                placeholder={
                  isRupeeSelected
                    ? inputAmountPlaceholder
                    : inputWeightPlaceholder
                }
                value={isRupeeSelected ? rupeeValue : gramValue}
                onChange={handleInputChange}
                className="input-field"
                style={{
                  border: "none",
                  outline: "none",
                  fontSize: "20px",
                  width: "100%",
                }}
                required
              />
              <div className="input-2">
                <span
                  className="rupee"
                  style={{
                    color: metalType == "SILVER" ? "#A6A6A6" : "#FFDF00",
                    margin: "0px 10px",
                  }}
                >
                  {isRupeeSelected ? `${gramValue}gm` : `₹${rupeeValue}`}
                </span>
              </div>
            </div>
          </div>
          <div className="text-area-container">
            <div className="text-area-content">
              <p className="qb">{quickBuy}</p>
              <p className="gil">
                {/* {metalType} In Locker : */}
                {metalType == "GOLD" ? goldInLocker : silverInLocker}{" "}
                <span
                  style={{
                    color: metalType == "GOLD" ? "##FFDF00" : "#A6A6A6",
                    fontWeight: "bold",
                  }}
                >
                  {metalType == "GOLD"
                    ? portfolioApiData?.data[0]?.augmont?.gold?.balQuantity
                    : portfolioApiData?.data[0]?.augmont?.silver?.balQuantity}
                  gm
                </span>
              </p>
            </div>
          </div>
          <div className="button-container" style={{ gap: "0.5rem" }}>
            {["100", "200", "300", "400", "500"].map((amount) => (
              <button
                type="button"
                className={`buttonBase ${
                  activeAmount === amount ? "buttonActive" : ""
                }`}
                style={{ fontWeight: "bold" }}
                key={amount}
                onClick={() => handleAmountButtonClick(amount)}
              >
                {isRupeeSelected ? `₹${amount}` : `${amount}gm`}
              </button>
            ))}
          </div>
          <div className="bottom-button-container">
            <button
              type="submit"
              className="btn-ir buy-btn "
              style={{
                borderRadius: "50px",
                fontWeight: "bold",
                width: "100%",
              }}
              // onClick={handleSubmit}
              // disabled={rupeeValue == "" && gramValue == ""}
            >
              {proceedToPay}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default BuySell;
