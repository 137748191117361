import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import Goldlogo from "../../images/NewGoldLogo.png";
import "./goldify.css";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { TbFileInvoice } from "react-icons/tb";

function GoldSoldInvoice() {
  const [updatedInvoicePath, setUpdatedInvoicePath] = useState("");
  const { t } = useTranslation();
  const { rateOfGold, gst } = t("goldInvoicePage");
  const {
    augmont,
    goldWeight,
    paymentDetails,
    weight,
    totalAmt,
    transactionId,
    uniqueId,
    invoiceNum,
    status,
    success,
    getInvoice,
  } = t("historyInvoicePage");
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, [navigate]);

  const {
    rateofgold,
    Goldweight,
    GST,
    Totalamount,
    transectionID,
    UniqueId,
    inoviceno,
    invoicepath,
  } = data;

  useEffect(() => {
    if (invoicepath?.includes("https://app.batuk.in/")) {
      const newPath = invoicepath.replace("https://app.batuk.in/", "");
      setUpdatedInvoicePath(newPath);
    } else {
      setUpdatedInvoicePath(invoicepath || "");
    }
  }, [data]);

  return (
    <div style={{ width: "100%" }}>
      <div className="row d-flex justify-content-center">
        <div
          className="col-md-11"
          style={{
            height: "auto",
            border: "2px solid #E3B041",
            borderRadius: "10px",
          }}
        >
          <div className="row d-flex justify-content-around">
            <div
              className="col d-flex m-2 p-1"
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                border: "2px solid #E3B041",
                borderRadius: "10px",
              }}
            >
              <div className="col-md-2">
                <img
                  src="/images/NewGoldLogo.png"
                  alt="logo"
                  className="img-fluid"
                  style={{ height: "80px" }}
                />
              </div>
              <div className="col-md-4 p-2 ml-5" style={{ height: "auto" }}>
                <h2
                  className="text-center fw-bold"
                  style={{ color: "#4A2A7D" }}
                >
                  {augmont}
                </h2>
                <p className="text-center" style={{ color: "#4A2A7D" }}>
                  {goldWeight} {Goldweight} gm
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              color: "#4A2A7D",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <h4 className="m-2 fw-bold">{paymentDetails}</h4>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{rateOfGold}</div>
              <div>{rateofgold}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{weight}</div>
              <div>{Goldweight}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{gst}</div>
              <div>{GST}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5>{totalAmt}</h5>
              <h5>{Totalamount}</h5>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div> {transactionId} </div>
              <div>{transectionID}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{uniqueId}</div>
              <div>{UniqueId}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{invoiceNum} </div>
              <div>{inoviceno}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5> {status} </h5>
              <h5 style={{ color: "green" }}>{success}</h5>
            </div>
          </div>
          <div className="text-center mb-3">
            <a
              target="blank"
              href={updatedInvoicePath}
              className="fs-4 fw-bold"
              style={{
                textDecoration: "none",
                color: "#4A2A7D",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                gap: "5px",
              }}
            >
              <Button
                variant="contained"
                startIcon={<TbFileInvoice />}
                sx={{
                  backgroundColor: "#4a2a7d",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#4a2a7d",
                  },
                }}
              >
                Invoice
              </Button>
            </a>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
}

export default GoldSoldInvoice;

// import React from "react";
// import Goldlogo from "../../images/NewGoldLogo.png";
// import { Link, useLocation } from "react-router-dom";
// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import "./goldify.css";

// function GoldSoldInvoice() {
//   const navigate = useNavigate();
//   useEffect(() => {
//     const login = sessionStorage.getItem("token");
//     if (!login) {
//       navigate("/");
//     }
//   }, []);

//   const location = useLocation();
//   console.log("dishant+++++++++++++++++", location);

//   //   const rateofgold = data.buydata.data[0].result.data.preTaxAmount;
//   //   const Goldweight = data.buydata.data[0].result.data.quantity;
//   //   const GST = data.buydata.data[0].result.data.taxes.totalTaxAmount;
//   //   // console.log("GSYTT", GST);
//   //   const Totalamount = data.buydata.data[0].result.data.totalAmount;
//   //   const transectionID = data.buydata.data[0].result.data.transactionId;
//   //   const UniqueId = data.buydata.data[0].result.data.uniqueId;
//   //   const inoviceno = data.buydata.data[0].result.data.invoiceNumber;
//   //   const invoicepath = data.buydata.data[0].invoicePath;
//   // console.log("invoice path", invoicepath);

//   return (
//     <div style={{ width: "100%" }}>
//       <div className="row d-flex justify-content-center">
//         <div
//           className="col-md-11  "
//           style={{
//             height: "auto",
//             border: "2px solid #E3B041",
//             borderRadius: "10px",
//           }}
//         >
//           <div className="row d-flex justify-content-around">
//             <div
//               className="col  d-flex m-2 p-1"
//               style={{
//                 display: "flex",
//                 justifyContent: "start",
//                 alignItems: "center",
//                 border: "2px solid #E3B041",
//                 borderRadius: "10px",
//               }}
//             >
//               <div className="col-md-2 ">
//                 <img
//                   src={Goldlogo}
//                   alt="here is logo"
//                   className="img-fluid"
//                   style={{ height: "80px" }}
//                 />
//               </div>
//               <div className="col-md-4   p-2 ml-5 " style={{ height: "auto" }}>
//                 <h2
//                   className="text-center fw-bold"
//                   style={{ color: "#4A2A7D" }}
//                 >
//                   Augmont
//                 </h2>
//                 <p className="text-center" style={{ color: "#4A2A7D" }}>
//                   {/* Gold Weight: {Goldweight} gm */}
//                 </p>
//               </div>
//             </div>
//           </div>

//           <div
//             style={{
//               color: "#4A2A7D",
//               display: "flex",
//               flexDirection: "column",
//               gap: "10px",
//             }}
//           >
//             <h4 className="m-2 fw-bold ">Payment Details</h4>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div>Rate of gold (₹)</div>
//               <div>{rateofgold}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div>Weight (g)</div>
//               <div>{Goldweight}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div>GST</div>
//               <div>{GST}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <h5>Total Amount</h5>
//               <h5>{Totalamount}</h5>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div> Transaction Id/Order Id </div>
//               <div>{transectionID}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div> Unique Id </div>
//               <div>{UniqueId}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div> Invoice Number </div>
//               <div>{inoviceno}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <h5> Status </h5>
//               <h5 style={{ color: "green" }}>Success</h5>
//             </div>
//           </div>
//           <div className=" text-center">
//             <a
//               target="blank"
//               //   href={`${process.env.REACT_APP_BASE_URL}/` + invoicepath}
//               className="  fs-4 fw-bold"
//               style={{
//                 textDecoration: "none",
//                 color: "#4A2A7D",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 textAlign: "center",
//                 gap: "5px",
//               }}
//             >
//               <h6>
//                 <i
//                   class="fa-regular fa-copy fs-5"
//                   style={{ marginTop: "9px" }}
//                 ></i>
//               </h6>
//               <span>Get Invoice</span>
//             </a>
//           </div>
//         </div>
//         <div className="col-md-2"></div>
//       </div>
//     </div>
//   );
// }
// export default GoldSoldInvoice;

// import React from "react";
// import Goldlogo from "../../images/NewGoldLogo.png";
// import { Link, useLocation } from "react-router-dom";
// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import "./goldify.css";

// function GoldSoldInvoice() {
//   const navigate = useNavigate();
//   useEffect(() => {
//     const login = sessionStorage.getItem("token");
//     if (!login) {
//       navigate("/");
//     }
//   }, []);

//   const location = useLocation();
//   console.log("dishant+++++++++++++++++", location);

//   //   const rateofgold = data.buydata.data[0].result.data.preTaxAmount;
//   //   const Goldweight = data.buydata.data[0].result.data.quantity;
//   //   const GST = data.buydata.data[0].result.data.taxes.totalTaxAmount;
//   //   // console.log("GSYTT", GST);
//   //   const Totalamount = data.buydata.data[0].result.data.totalAmount;
//   //   const transectionID = data.buydata.data[0].result.data.transactionId;
//   //   const UniqueId = data.buydata.data[0].result.data.uniqueId;
//   //   const inoviceno = data.buydata.data[0].result.data.invoiceNumber;
//   //   const invoicepath = data.buydata.data[0].invoicePath;
//   // console.log("invoice path", invoicepath);

//   return (
//     <div style={{ width: "100%" }}>
//       <div className="row d-flex justify-content-center">
//         <div
//           className="col-md-11  "
//           style={{
//             height: "auto",
//             border: "2px solid #E3B041",
//             borderRadius: "10px",
//           }}
//         >
//           <div className="row d-flex justify-content-around">
//             <div
//               className="col  d-flex m-2 p-1"
//               style={{
//                 display: "flex",
//                 justifyContent: "start",
//                 alignItems: "center",
//                 border: "2px solid #E3B041",
//                 borderRadius: "10px",
//               }}
//             >
//               <div className="col-md-2 ">
//                 <img
//                   src={Goldlogo}
//                   alt="here is logo"
//                   className="img-fluid"
//                   style={{ height: "80px" }}
//                 />
//               </div>
//               <div className="col-md-4   p-2 ml-5 " style={{ height: "auto" }}>
//                 <h2
//                   className="text-center fw-bold"
//                   style={{ color: "#4A2A7D" }}
//                 >
//                   Augmont
//                 </h2>
//                 <p className="text-center" style={{ color: "#4A2A7D" }}>
//                   {/* Gold Weight: {Goldweight} gm */}
//                 </p>
//               </div>
//             </div>
//           </div>

//           <div
//             style={{
//               color: "#4A2A7D",
//               display: "flex",
//               flexDirection: "column",
//               gap: "10px",
//             }}
//           >
//             <h4 className="m-2 fw-bold ">Payment Details</h4>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div>Rate of gold (₹)</div>
//               <div>{rateofgold}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div>Weight (g)</div>
//               <div>{Goldweight}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div>GST</div>
//               <div>{GST}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <h5>Total Amount</h5>
//               <h5>{Totalamount}</h5>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div> Transaction Id/Order Id </div>
//               <div>{transectionID}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div> Unique Id </div>
//               <div>{UniqueId}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div> Invoice Number </div>
//               <div>{inoviceno}</div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <h5> Status </h5>
//               <h5 style={{ color: "green" }}>Success</h5>
//             </div>
//           </div>
//           <div className=" text-center">
//             <a
//               target="blank"
//               //   href={`${process.env.REACT_APP_BASE_URL}/` + invoicepath}
//               className="  fs-4 fw-bold"
//               style={{
//                 textDecoration: "none",
//                 color: "#4A2A7D",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 textAlign: "center",
//                 gap: "5px",
//               }}
//             >
//               <h6>
//                 <i
//                   class="fa-regular fa-copy fs-5"
//                   style={{ marginTop: "9px" }}
//                 ></i>
//               </h6>
//               <span>Get Invoice</span>
//             </a>
//           </div>
//         </div>
//         <div className="col-md-2"></div>
//       </div>
//     </div>
//   );
// }
// export default GoldSoldInvoice;
